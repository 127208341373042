<template>


  <div v-if="!store.getters['server/isReady']" style="width: 100%;position: absolute;left: 0px; top: 0px;z-index: 999999999 ">
    <el-progress
        :percentage="100"
        status="primary"
        :indeterminate="true"
        :duration="2"
        :show-text="false"
    />
  </div>



  <context-menu ref="contextMenuRef"></context-menu>
  <showtip></showtip>




  <div v-if="store.state.auth">

    
      <edit-calendars ref="editCalendarsRef"></edit-calendars>
      <link-objects ref="linkObjectsRef"></link-objects>
      <log-objects ref="logObjectsRef"></log-objects>

      <edit-share v-if="store.state.server.isPlus" ref="editShareRef"></edit-share>
      <edit-shares v-if="store.state.server.isPlus" ref="editSharesRef"></edit-shares>

      <edit-group ref="editGroupRef"></edit-group>
      <edit-users ref="editUsersRef"></edit-users>
      <edit-server ref="editServerRef"></edit-server>
      <edit-drivers ref="editDriversRef"></edit-drivers>
      <edit-maintenances ref="editMaintenancesRef"></edit-maintenances>
    
    
    <!-- El componente de Tema se carga fuera de los flags condicionales -->
    <edit-theme v-if="store.state.server.isPlus" ref="editThemeRef"></edit-theme>

    <show-invoices ref="invoicesRef"></show-invoices>
    <show-invoices-manager ref="invoicesManagerRef"></show-invoices-manager>

    <edit-integrations ref="integrationsRef"></edit-integrations>
    <edit-user ref="editUserRef"></edit-user>
    <edit-notifications ref="editNotificationsRef"></edit-notifications>
    <edit-events ref="editEventsRef"></edit-events>
    <edit-device ref="editDeviceRef"></edit-device>
    <qr-device ref="qrDeviceRef"></qr-device>
    <show-graphic ref="showGraphicsRef"></show-graphic>






  <div id="head">
    <div id="btnmenu" v-if="!$route.meta.shown" @click.stop="toggleMenu">
      <i class="fas fa-bars"></i>
    </div>
    <div id="logo" >
      <img v-if="store.state.server.labelConf.headLogo.image" onclick="window.location.href = '/'" src="/tarkan/assets/custom/logo.png" :style="{ width: isMobileView ? '7rem' : '11rem' }" >
      <div v-else style="font-weight: bold;text-transform: uppercase;font-family: montserrat, roboto; transform: scale(0.85);">
        <a onclick="window.location.href = '/'" style="color: var(--el-text-color-primary); text-decoration: none;">{{store.state.server.labelConf.headLogo.text}}</a></div>
    </div>
    <div style="display: flex; align-items: center; flex-wrap: nowrap; justify-content: flex-end;" class="header-right-container">
      <div id="weather" class="header-info-box">
        <el-icon v-if="weather.icon" class="weather-icon">
          <i :class="weather.icon"></i>
        </el-icon>
        <span v-if="weather.temperature !== null" class="weather-text">
          <span class="city-name">{{ weather.city }}</span> {{ weather.temperature }}°C
        </span>
        <span v-if="localTime" class="time-text">
          {{ isMobileView ? formatTimeOnly(localTime) : localTime }}
        </span>
      </div>

      <el-tooltip :content="(store.state.events.mute)? KT('menu.soundNotification') : KT('menu.noSoundNotification')">
        <div id="mute" @click="store.dispatch('events/toggleMute')" class="header-action-btn">
          <span v-if="store.state.events.mute"><i class="fas fa-volume-mute" style="color: var(--el-color-info);"></i></span>
          <span v-else><i class="fas fa-volume-up" style="color: var(--el-color-primary);"></i></span>
        </div>
      </el-tooltip>

      <push-notification-btn v-if="store.state.auth" class="push-notification-container"></push-notification-btn>


      <div id="user" @click="userMenu($event)" style="cursor: pointer;">
        <div class="uname" v-if="store.state.auth && !store.state.auth.attributes['isShared']" style="font-size: 0.8rem;margin: 0.5rem 0.5rem;">{{store.state.auth.name}}</div>
        <div class="uname" v-else style="text-align: right;font-size: 1.4rem;margin: 0.3rem 0.5rem;">
          <div style="font-size: 0.3rem;">Expira em:</div>
          <div style="font-size: 0.5rem">{{store.getters.expiresCountDown}}</div>
        </div>
        <i :style="{ fontSize: isMobileView ? '1.2rem' : '1.4rem', margin: isMobileView ? '0.2rem 0.3rem' : '0.3rem 0.5rem' }" class="fas fa-user-circle"></i>
      </div>
    </div>
  </div>
  <div id="content">


    <template v-if="store.getters['isDriver']">
      <router-view></router-view>

    </template>
    <template v-else>
  <div id="menu" :class="{isopen: menuShown && !$route.meta.shown}" v-if="store.state.auth && !store.state.auth.attributes['isShared'] && (!$route.meta.shown || $route.path === '/home')">
      <ul>
        <router-link v-if="store.getters.advancedPermissions(8)" to="/devices" custom v-slot="{ href,  navigate, isActive, isExactActive }">
          <li :class="{active: isActive || isExactActive,'exact-active': isExactActive}">
            <a :href="href" @click="navigate">
              <el-icon>
                <i class="fas fa-location-arrow"></i>
              </el-icon>
              <span class="text" >{{$t('menu.devices')}}</span>
            </a>
          </li>
        </router-link>




        <router-link  v-if="store.getters.advancedPermissions(72)" to="/reports" custom v-slot="{ href,  navigate, isActive, isExactActive }">
          <li :class="{active: isActive,'exact-active': isExactActive}">
            <a :href="href" @click="navigate">
              <el-icon>
                <i class="fas fa-chart-bar"></i>
              </el-icon>
              <span class="text" >{{$t('menu.reports')}}</span>
            </a>
          </li>
        </router-link>


        
        <router-link  v-if="store.getters.advancedPermissions(40)" to="/geofence" custom v-slot="{ href,  navigate, isActive, isExactActive }">
          <li :class="{active: isActive,'exact-active': isExactActive}">
            <a :href="href" @click="navigate">
              <el-icon>
                <i class="fas fa-draw-polygon"></i>
              </el-icon>
              <span class="text" >{{$t('menu.geofence')}}</span>
            </a>
          </li>
        </router-link>


        <router-link  v-if="store.getters.advancedPermissions(56)" to="/commands" custom v-slot="{ href,  navigate, isActive, isExactActive }">
          <li :class="{active: isActive,'exact-active': isExactActive}">
            <a :href="href" @click="navigate">
              <el-icon>
                <i class="far fa-keyboard"></i>
              </el-icon>
              <span class="text" >{{$t('menu.commands')}}</span>
            </a>
          </li>
        </router-link>



        <router-link  v-if="store.getters.advancedPermissions(48)" to="/groups" custom v-slot="{ href,  navigate, isActive, isExactActive }">
          <li :class="{active: isActive,'exact-active': isExactActive}">
            <a :href="href" @click="navigate">
              <el-icon>
                <i class="far fa-object-group"></i>
              </el-icon>
              <span class="text" >{{$t('menu.groups')}}</span>
            </a>
          </li>
        </router-link>

        

        <router-link to="/notifications" custom v-slot="{ href,  navigate, isActive, isExactActive }">
          <li :class="{active: isActive,'exact-active': isExactActive}">
            <a :href="href" @click="navigate">
              <el-icon>
                <i class="fas fa-bell"></i>
              </el-icon>
              <span class="text" >{{$t('menu.notifications')}}</span>
            </a>
          </li>
        </router-link>
     




        <div class="indicator"></div>


      </ul>


      <div id="version" >
               <template v-if="store.state.server.serverInfo.version">
         {{$t('version')}} {{$t('id')}} @ {{store.state.server.serverInfo.version || '-'}}

          


        </template>
        


      </div>




      
  </div>
  <div id="open" :class="{minimized: minimized,bottom: ($route.meta.mobileBottom),mobileExpanded: mobileExpand,shown: ($route.meta.shown),editing: store.state.geofences.mapEditing,allowExpand: $route.meta.allowExpand,expanded: ($route.meta.allowExpand && $route.query.expand==='true') }">
    <div style="width: calc(100%); " :style="{display: (store.state.geofences.mapEditing)?'none':'' }">
      <div id="heading" style="margin-top: 20px;">
        <span @click="$route.meta.backBtn?$router.push($route.meta.backBtn):$router.back()"><i class="fas fa-angle-double-left"></i></span>
        {{KT($route.meta.title || 'page')}}
        <span @click="$router.push('/home')"><i class="fas fa-times-circle"></i></span></div>

      <div v-if="($route.meta.mobileBottom)" @click="minimized = !minimized" class="showOnMobile" style="position: absolute;right: 35px;top: 25px;font-size: 18px;"><i class="fas fa-window-minimize"></i></div>
      <div v-if="($route.meta.mobileBottom)" @click="$router.push('/home')" class="showOnMobile" style="position: absolute;right: 5px;top: 25px;font-size: 18px;"><i class="fas fa-times-circle"></i></div>
      <div v-if="($route.meta.mobileBottom)" id="expander" @click="mobileExpand = !mobileExpand">
        <span v-if="!mobileExpand"><i  class="fas fa-angle-double-up"></i></span>
        <span v-else><i  class="fas fa-angle-double-down"></i></span>
      </div>


      <div id="rv"><router-view ></router-view></div>

    </div>
    <div v-if="store.state.geofences.mapEditing">
      <div style="padding: 10px;"><el-button @click="store.dispatch('geofences/disableEditing')" type="primary">Concluir</el-button></div>
      <!-- Modal de información sobre la versión 
      <div style="padding: 10px;"><el-button type="warning" plain>{{KT('reset')}}</el-button></div>
-->

      <div style="padding: 10px;"><el-button type="danger" plain>{{KT('cancel')}}</el-button></div>
    </div>

    <div v-if="$route.meta.allowExpand" class="expandBtn" @click="$router.push({query: {expand: $route.query.expand==='true'?'false':'true'}})"><i class="fas fa-angle-double-right"></i></div>
  </div>

  <div id="main" 
       :class="{menuShown: menuShown,editing: store.state.geofences.mapEditing,minimized: minimized,bottom: ($route.meta.mobileBottom),shown: ($route.meta.shown)}" 
       :style="{width: (store.state.auth.attributes['isShared'])?'100vw':''}"
       @click="handleMapClick">

    
    <street-view v-if="store.state.devices.streetview" ></street-view>

    
    <kore-map></kore-map>


  </div>
    </template>
  </div>
  </div>
  <div v-else>
    <router-view></router-view>
  </div>
</template>

<script setup>

import {defineAsyncComponent,ref,onMounted,watch,provide,nextTick} from 'vue'
import { useStore } from 'vuex'
import axios from 'axios';

// No importamos el archivo de colores directamente, ya que está cargado globalmente en index.html

import 'element-plus/es/components/button/style/css'
import 'element-plus/es/components/icon/style/css'
import 'element-plus/es/components/tooltip/style/css'
import 'element-plus/es/components/progress/style/css'
import {ElProgress} from "element-plus/es/components/progress";

import {ElButton} from "element-plus/es/components/button";
import {ElIcon} from "element-plus/es/components/icon";
import {ElTooltip} from "element-plus/es/components/tooltip";

import router from "./routes";


const StreetView = defineAsyncComponent(()=> import("./tarkan/components/street-view") );


const KoreMap = defineAsyncComponent(()=> import("./tarkan/components/kore-map") );

import KT from './tarkan/func/kt'
//import scRecorder from './tarkan/func/recorder'
import actAnchor from './tarkan/func/actAnchor'


//window.SCREEN_RECORDER = new scRecorder();


import "leaflet/dist/leaflet.css"

const ContextMenu = defineAsyncComponent(()=> import("./tarkan/components/context-menu") );
const ShowInvoices = defineAsyncComponent(()=> import("./tarkan/components/views/show-invoices.vue") );
const ShowInvoicesManager = defineAsyncComponent(()=> import("./tarkan/components/views/show-invoices-manager.vue") );


const EditUser = defineAsyncComponent(()=> import("./tarkan/components/views/edit-user") );

const EditShare  = defineAsyncComponent(()=> import("./tarkan/components/views/edit-share") );
const EditShares  = defineAsyncComponent(()=> import("./tarkan/components/views/edit-shares") );
const EditGroup  = defineAsyncComponent(()=> import("./tarkan/components/views/edit-group") );
const EditUsers  = defineAsyncComponent(()=> import("./tarkan/components/views/edit-users") );
const EditServer = defineAsyncComponent(()=> import("./tarkan/components/views/edit-server") );

const EditDrivers  = defineAsyncComponent(()=> import("./tarkan/components/views/edit-drivers") );
const LinkObjects  = defineAsyncComponent(()=> import("./tarkan/components/views/link-objects") );
const LogObjects  = defineAsyncComponent(()=> import("./tarkan/components/views/log-objects") );
const EditCalendars  = defineAsyncComponent(()=> import("./tarkan/components/views/edit-calendars") );
const EditMaintenances  = defineAsyncComponent(()=> import("./tarkan/components/views/edit-maintenances") );


// Importamos el componente EditTheme fuera de los flags condicionales para asegurar que siempre esté disponible
const EditTheme  = defineAsyncComponent({
  loader: () => import("./tarkan/components/views/edit-theme"),
  // Manejamos errores de carga para facilitar la depuración
  onError(error, retry, fail, attempts) {
    console.error('Error cargando el componente EditTheme:', error);
    if (attempts <= 3) {
      // Reintentar automáticamente
      retry();
    } else {
      // Después de 3 intentos, fallar
      fail();
    }
  }
});

const EditIntegrations  = defineAsyncComponent(()=> import("./tarkan/components/views/edit-integrations") );

const EditDevice  = defineAsyncComponent(()=> import("./tarkan/components/views/edit-device") );
const EditNotifications  = defineAsyncComponent(()=> import("./tarkan/components/views/edit-notifications") );
const EditEvents = defineAsyncComponent(()=> import("./tarkan/components/views/edit-events") );

const QrDevice  = defineAsyncComponent(()=> import("./tarkan/components/views/qr-device") );

const Showtip  = defineAsyncComponent(()=> import("./tarkan/components/showtip") );


const ShowGraphic  = defineAsyncComponent(()=> import("./tarkan/components/views/show-graphic") );

const PushNotificationBtn  = defineAsyncComponent(()=> import("./tarkan/components/push-notification-btn") );


/* declare html object component refs */
const contextMenuRef = ref(null);
const integrationsRef = ref(null);
const radialMenuRef = ref(null);
const editDeviceRef = ref(null);
const qrDeviceRef = ref(null);
const editUserRef = ref(null);
const editUsersRef = ref(null);
const editShareRef = ref(null);
const editSharesRef = ref(null);
const editGroupRef = ref(null);
const editNotificationsRef = ref(null);
const editEventsRef = ref(null);
const editServerRef = ref(null);
const editDriversRef = ref(null);
const linkObjectsRef = ref(null);
const logObjectsRef = ref(null);
const editCalendarsRef = ref(null);
const editMaintenancesRef = ref(null);
const editThemeRef = ref(null);
const showGraphicsRef = ref(null);

const invoicesRef = ref(null);
const invoicesManagerRef = ref(null);

const mobileExpand = ref(false);
const menuShown = ref(false);
const minimized = ref(false);
const isMobileView = ref(false);
/* declare variable object refs */



const store = useStore();



function generateRandomToken(){
  const letters = "TKZYxLSOPERT123965U".split("")
  let tmp = [];
  let i = 0;
  while(i<20){

    const rand = Math.round(Math.random()*(letters.length-1));

    tmp.push(letters[rand]);

    i++;
  }
  return tmp.join("");
}

router.afterEach((to)=>{
  minimized.value = false;
  
  // Esconder o menu quando estiver em tela interna
  if (to.meta.shown && to.path !== '/home') {
    menuShown.value = false;
  }
})

const formatTimeOnly = (timeString) => {
  try {
    // Extract just the hours and minutes from the time string
    const timeMatch = timeString.match(/(\d{1,2}):(\d{2})/);
    if (timeMatch) {
      return `${timeMatch[1]}:${timeMatch[2]}`;
    }
    return timeString;
  } catch (error) {
    console.error('Error formatting time:', error);
    return timeString;
  }
};

// Manejar clics en el mapa sin cerrar el encabezado
const handleMapClick = (e) => {
  // Evitar que el clic en el mapa cierre el menú en dispositivos móviles
  // pero solo si no es un clic en un control del mapa
  if (e.target && e.target.classList && 
     (e.target.classList.contains('leaflet-control') || 
      e.target.closest('.leaflet-control'))) {
    return; // No hacer nada si el clic es en un control de Leaflet
  }
  
  // No ocultar el encabezado
  // Podemos mantener el menú desplegado si se desea
  // o podríamos cerrarlo con: menuShown.value = false;
};

// Función para alternar el menú lateral con manejo especial para dispositivos móviles
const toggleMenu = (e) => {
  // Detener la propagación del evento para evitar que interfiera con otros manejadores
  e.stopPropagation();
  
  // Alternar el estado del menú
  menuShown.value = !menuShown.value;
};

onMounted(()=> {
  // Detectar se é dispositivo móvel
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) 
    || window.innerWidth <= 768;
  
  if (isMobile) {
    document.body.classList.add('mobile');
    isMobileView.value = true;
  }
  
  // Adicionar event listener para resize da janela
  window.addEventListener('resize', () => {
    isMobileView.value = window.innerWidth <= 768;
  });

  window.localStorage.setItem('query','');

  if (!window.localStorage.getItem('TKSESSIONTOKEN')) {
    const token = generateRandomToken();
    window.localStorage.setItem('TKSESSIONTOKEN', token);
  }

  // Pré-carrega imagens comuns para melhorar o desempenho
  try {
    // Verificar se o módulo existe no store de forma segura
    if (store._modules && store._modules.root && 
        store._modules.root._children && 
        store._modules.root._children.imageCache) {
      store.dispatch('imageCache/preloadCommonImages');
    }
  } catch (err) {
    console.warn('Erro ao pré-carregar imagens:', err);
  }

  //store.dispatch("server/loadConfig");



})







const userMenu = (e)=>{
  let tmp = [];

  if(!store.state.auth.attributes['isShared']) {

    tmp.push({
      text: KT('usermenu.account'), 
      icon: 'fas fa-user-cog',
      cb: () => {
        editUserRef.value.editUser();
      }
    });

    /*
    if (store.state.auth.administrator && store.state.server.isPlus) {
      tmp.push({
        text: KT('usermenu.reseller'), cb: () => {
          editThemeRef.value.showTheme();
        }
      });
    }*/


    if (store.state.auth.administrator && store.state.server.isPlus) {
      tmp.push({
        text: KT('usermenu.logs'), 
        icon: 'fas fa-history',
        cb: () => {
          logObjectsRef.value.showLogs('all');
        }
      });

      if(store.getters["server/getAttribute"]('tarkan.enableBilling',false)) {
        tmp.push({
          text: KT('usermenu.integrations'),
          icon: 'fas fa-plug',
          cb: () => {
            integrationsRef.value.showIntegrations();
          }
        });

        tmp.push({
          text: KT('usermenu.billing'),
          icon: 'fas fa-file-invoice-dollar',
          cb: () => {
            invoicesManagerRef.value.showInvoices();
          }
        });
      }
    }

    if(store.getters["server/getAttribute"]('tarkan.enableBilling', false)) {
  tmp.push({
    text: KT('usermenu.invoices'),
    icon: 'fas fa-file-invoice',
    cb: () => {
      invoicesRef.value.showInvoices();
    }
  });
}

    if (store.state.auth.administrator && store.state.server.isPlus) {
      tmp.push({
        text: KT('usermenu.theme'), 
        icon: 'fas fa-palette',
        cb: () => {
          if (editThemeRef.value) {
            editThemeRef.value.showTheme();
          } else {
            console.warn('editThemeRef no está inicializado correctamente');
            // Intentar cargar el componente nuevamente, si es posible
            nextTick(() => {
              if (editThemeRef.value) {
                editThemeRef.value.showTheme();
              }
            });
          }
        }
      });
    }

    
    if (store.getters.advancedPermissions(16)
        /*store.state.auth.administrator || store.state.auth.userLimit > 0 || store.state.auth.userLimit === -1*/
    ) {
      tmp.push({
        text: KT('usermenu.users'), 
        icon: 'fas fa-users',
        cb: () => {
          if (editUsersRef.value) {
            editUsersRef.value.showUsers();
          } else {
            console.warn('editUsersRef no está inicializado correctamente');
            // Intentar cargar el componente nuevamente, si es posible
            nextTick(() => {
              if (editUsersRef.value) {
                editUsersRef.value.showUsers();
              }
            });
          }
        }
      });
    }
    


    
    if (store.getters.advancedPermissions(64)) {
      tmp.push({
        text: KT('usermenu.computedAttributes'), 
        icon: 'fas fa-calculator',
        cb: () => {
          router.push("/computed")
        }
      });
    }
    


    
    if (store.getters.isAdmin) {
      tmp.push({
        text: KT('usermenu.server'), 
        icon: 'fas fa-server',
        cb: () => {
          editServerRef.value.showServer();
        }
      });
    }
    


    if (store.getters.advancedPermissions(32)) {
      tmp.push({
        text: KT('usermenu.notifications'), 
        icon: 'fas fa-bell',
        cb: () => {
          editNotificationsRef.value.showNotifications();
        }
      });
    }

    if (store.getters.advancedPermissions(36)) {
      tmp.push({
        text: KT('usermenu.notifications2'), 
        icon: 'fas fa-exclamation-circle',
        cb: () => {
          editEventsRef.value.showEvents();
        }
      });
    }
    
    if (store.getters.advancedPermissions(80)) {
      tmp.push({
        text: KT('usermenu.drivers'), 
        icon: 'fas fa-id-card',
        cb: () => {
          editDriversRef.value.showDrivers();
        }
      });
    }

    

    
    if (store.getters.advancedPermissions(88)) {
      tmp.push({
        text: KT('usermenu.calendars'), 
        icon: 'fas fa-calendar-alt',
        cb: () => {
          editCalendarsRef.value.showCalendars();
        }
      });
    }

    

    
    if (store.getters.advancedPermissions(96)) {
      tmp.push({
        text: KT('usermenu.maintenance'), 
        icon: 'fas fa-tools',
        cb: () => {
          editMaintenancesRef.value.showMaintenances();
        }
      });
    }
    

  }


  tmp.push({
    text: KT('usermenu.logout'), 
    icon: 'fas fa-sign-out-alt',
    cb: () => {
      store.dispatch("logout").then(()=>{
        router.push('/login');
      })

    }
  });

  contextMenuRef.value.openMenu({evt: e, menus: tmp})
}


const showRouteMarker = ref(false);

const setShowMarker = (b)=>{
  showRouteMarker.value = b;
}




/* provide references for global use */

provide('setRouteMarker',setShowMarker)


provide("act-anchor",actAnchor);


provide('contextMenu',contextMenuRef);
provide('radialMenu',radialMenuRef);
provide('edit-device',editDeviceRef);
provide('qr-device',qrDeviceRef);
provide('edit-user',editUserRef);
provide('edit-users',editUsersRef);
provide('edit-share',editShareRef);
provide('edit-shares',editSharesRef);
provide('edit-group',editGroupRef);
provide('link-objects',linkObjectsRef);
provide('log-objects',logObjectsRef);

provide('show-graphics',showGraphicsRef);


////////////////////////////////////////

const weather = ref({
  city: '',
  temperature: null,
  windSpeed: null,
  icon: '',
});

const localTime = ref('');
let offset = 0;
onMounted(async () => {
  await getWeather();  
  setInterval(() => {
    const now = new Date(); // Hora actual
    localTime.value = getLocalTime(now, offset); // Actualizar la hora local
  }, 60000); // Cada 60000 milisegundos (1 minuto)
});





//onMounted(async () => {
 // await getWeather();
//});

const getWeather = async () => {
  try {
    // Obtener la IP pública del cliente
    const ipResponse = await axios.get('https://api.ipify.org/?format=json');
    const userIp = ipResponse.data.ip;  // Obtener la IP pública

    // Usar ipinfo.io para obtener la ubicación del usuario (sin necesidad de clave)
    const locationResponse = await axios.get(`https://ipinfo.io/${userIp}/json`);

    const [lat, lon] = locationResponse.data.loc.split(','); // `loc` devuelve lat,lon como una cadena
    const city = locationResponse.data.city;
    const timezone = new Date().getTimezoneOffset() / 60; // Obtener el offset local en horas

    // Obtener el clima actual usando Open Meteo
    const weatherResponse = await axios.get(`https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lon}&current_weather=true`);

    // Asignar datos del clima
    weather.value.city = city;
    weather.value.temperature = Math.round(weatherResponse.data.current_weather.temperature); // Redondear la temperatura
    weather.value.windSpeed = weatherResponse.data.current_weather.wind_speed;

    // Obtener el código del clima
    const weatherCode = weatherResponse.data.current_weather.weathercode;
    weather.value.icon = getWeatherIcon(weatherCode);

    // Obtener la hora local
    const time = new Date();
    localTime.value = getLocalTime(time, timezone); // Ajustar la hora local usando el timezone
  } catch (error) {
    console.error('Error fetching weather data:', error.response ? error.response.data : error.message);
  }
};


const getLocalTime = (date, offset) => {
   // Convertir el offset de horas a milisegundos
   //const offsetInMilliseconds = offset * 3600 * 1000;

// Aplicar el offset a la hora actual
//const localDate = new Date(date.getTime() + offsetInMilliseconds);


const localDate = new Date(date.getTime() + offset);
  //const localDate = new Date(date.getTime() + offset * 1000);
 // const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZoneName: 'short' };
  return localDate.toLocaleString(undefined, options); 
};




const getWeatherIcon = (weatherCode) => {
  const icons = {
    0: 'fas fa-sun',           
    1: 'fas fa-cloud-sun',     
    2: 'fas fa-cloud',         
    3: 'fas fa-cloud',         
    45: 'fas fa-smog',         
    48: 'fas fa-smog',         
    61: 'fas fa-cloud-showers-heavy', 
    63: 'fas fa-cloud-showers-heavy', 
    65: 'fas fa-cloud-showers-heavy', 
    71: 'fas fa-snowflake',    
    73: 'fas fa-snowflake',    
    75: 'fas fa-snowflake',    
    80: 'fas fa-cloud-showers-heavy', 
    81: 'fas fa-cloud-showers-heavy', 
    82: 'fas fa-cloud-showers-heavy', 
  };

  return icons[weatherCode] || 'fas fa-question-circle'; 
};

watch(localTime, (newValue) => {
  console.log('Local time updated:', newValue);
});

// Iniciar la obtención de datos del clima
getWeather();

////////////////////////////////////////

</script>

<style>

.showOnMobile{
  display: none;
}

.editing .leaflet-container {
  cursor:crosshair !important;
}

body.el-popup-parent--hidden{
  padding-right: 0px !important;
}



body{
  overflow: hidden;
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
}


*{
  margin: 0px;
  padding: 0px;
}


#app {
  font-family: 'Trebuchet MS';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c2d50;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
}

#head{
  height: 2rem;
  overflow: hidden;
  border-bottom: rgba(0,0,0,0.08) 1px solid;
  background: var(--el-bg-color);
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
  box-shadow: 0 1px 8px rgba(0,0,0,0.05);
  position: relative;
  z-index: 1050; /* Aumentado para estar por encima de elementos del mapa */
  width: 100%;
}

#head #user{
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  cursor: pointer;
  border-radius: 18px;
  transition: all 0.2s;
}

#head #user:hover {
  background: rgba(0,0,0,0.03);
}

#head #user .uname {
  font-weight: 500;
  color: var(--el-color-primary-dark-1);
}

#head #user i {
  color: var(--el-color-primary);
  transition: all 0.2s;
}

#head #user:hover i {
  transform: scale(1.1);
}

#logo{
  padding: 0.3rem 0.5rem;
  display: flex;
  align-items: center;
}

#logo img {
  transition: all 0.3s;
}

#logo img:hover {
  transform: scale(1.02);
}

#content{
  display: flex;
  height: calc(var(--vh,100vh) - 2rem);
}

#menu{
  width: 4rem;
  height: calc(var(--vh,100vh) - 2rem);
  background: var(--el-color-primary);
  position: relative;
  transition: all 0.3s;
  box-shadow: 1px 0 5px rgba(0,0,0,0.1);
  z-index: 10;
}

#version{
  position: absolute;
  bottom: 0.5rem;
  left: 0.25rem;
  background: rgba(255,255,255,0.1);
  color: var(--el-bg-color);
  padding: 0.4rem;
  font-size: 0.55rem;
  border-radius: 0.3rem;
  width: 3.5rem;
  box-sizing: border-box;
  text-align: center;
  transition: all 0.3s;
}

#version:hover {
  background: rgba(255,255,255,0.2);
}

#open{
  height: calc(100vh - 2rem);
  background: var(--el-bg-color);
  color: var(--el-text-color-primary);
  display: flex;
  align-content: center;
  justify-content: space-between;
  transition: all 0.3s ease;
  opacity: 0;
  width: 0px;
  overflow: hidden;
  box-shadow: 2px 0 15px rgba(0,0,0,0.07);
  padding-top: 20px; /* Añadido padding superior para todas las vistas */
}

#open.allowExpand .expandBtn{
  position: absolute;
  left: 555px;
  top: 50%;
  z-index: 9999999999;
  border: none;
  background: var(--el-color-primary);
  padding: 10px 5px;
  padding-top: 25px;
  padding-bottom: 25px;
  color: white;
  transform: translate(0,-50%);
  border-radius: 0px 8px 8px 0px;
  box-shadow: 3px 0 8px rgba(0,0,0,0.15);
  transition: all 0.2s;
}

#open.allowExpand .expandBtn:hover {
  background: var(--el-color-primary-light-1);
  box-shadow: 4px 0 12px rgba(0,0,0,0.2);
}

#open.shown{
  opacity: 1;
  width: 700px;
}

#open.allowExpand.expanded{
  width: 1400px !important;
}

#open.allowExpand.expanded .expandBtn{
  left: 805px;
}

#open.allowExpand.expanded .expandBtn i{
  transform: rotate(180deg)
}

#open.shown.editing{
  width: 130px !important;
}

#open.shown.editing div{
  display: flex;
  flex-direction: column-reverse;
  align-content: space-between;
  justify-content: space-between;
}

#open #rv{
  overflow-y: auto;
  height: calc(100vh - 130px);
  padding: 12px;
  scrollbar-width: thin;
}

#open.minimized{
  height: 35px !important;
}

::-webkit-scrollbar{
  width: 8px;
  height: 3px;
  background: #f5f5f5;
}

::-webkit-scrollbar-thumb{
  width: 8px;
  height: 5px;
  background: #cccccc;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--el-color-primary-light-3);
}

#menu ul{
  list-style: none;
  margin-top: 1rem;
  padding: 0;
}

#menu ul li{
  position: relative;
  width: 4rem;
  height: 4rem;
  z-index: 5;
  transition: all 0.2s;
}

#menu ul li:hover {
  background: rgba(255,255,255,0.1);
}

#menu ul li.active {
  background: var(--el-color-primary-light-1);
  border-left: 3px solid var(--el-bg-color);
}

#menu ul li a{
  color: var(--el-text-color-primary);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-decoration: none;
}

#menu ul li a .el-icon{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  color: var(--el-bg-color);
  width: 2.2rem;
  height: 2.2rem;
  margin-bottom: 0.3rem;
  transition: all 0.2s;
}

#menu ul li.active a .el-icon{
  transform: scale(1.1);
}

#menu ul li a .text{
  position: relative;
  display: block;
  color: var(--el-bg-color);
  font-weight: 400;
  font-size: 0.65rem;
  letter-spacing: 0.01rem;
  transition: all 0.2s;
  opacity: 0.85;
  text-align: center;
  width: 100%;
}

#menu ul li.active a .text{
  opacity: 1;
  font-weight: 500;
}

#menu ul li:hover:not(.active) a .el-icon{
  transform: translateY(-2px);
}

#main{
  width: calc(var(--vw,100vw) - 4rem);
  height: calc(var(--vh,100vh) - 2rem);
  transition: all 0.3s;
}

#main.minimized{
  height: calc(100vh - 50px) !important;
}

.indicator{
  display: none;
}


#heading{
  text-align: center;
  font-weight: bold;
  background: var(--el-color-primary);
  border-radius: 20px;
  padding: 10px;
  color: var(--el-color-white);
  position: relative;
  z-index: 0;
  margin: 10px;
}


#heading span:first-child{
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 6px;
  font-size: 25px;
  cursor: pointer;
}

#heading span:last-child{
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 6px;
  font-size: 25px;
  cursor: pointer;
}

body.rtl #app div #content{
  flex-direction: row-reverse !important;
}

body.rtl #app div #content #menu ul .indicator{
  left: -40px;
}
body.rtl #app div #content #menu ul .indicator:before{
  left: calc(50% + 7px);
  top: -18px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 20px;
  box-shadow: -10px 0px 0 0 var(--el-bg-color)
}

body.rtl #app div #content #menu ul .indicator:after{
  left: calc(50% + 7px);
  bottom: -18px;
  border-top-right-radius: 0px;
  border-top-left-radius: 20px;
  box-shadow: -10px 0px 0 0 var(--el-bg-color)
}

body.rtl #app div #content #menu ul li a .text{
  left: 45px;
}

body.rtl #app div #content #menu ul li.active a .text{
  left: 20px;
}

body.rtl #app div #content #menu ul li a .el-icon{
  transform: translateX(-7px);
}

body.rtl #app div #content #menu ul li.active a .el-icon{
    transform: translateX(-45px);
}


.notification-soft-red{
  --el-color-white: #ffdddd!important;
  --el-notification-icon-color:#181818!important;
  --el-notification-content-color: #181818!important;
}

.notification-soft-red .el-icon{
  color: #181818 !important;
}

.notification-red{
  --el-color-white: #f44336!important;
  --el-notification-icon-color: white!important;
  --el-notification-title-color:white!important;
}

.notification-red .el-icon{
  color: white !important;
}


.notification-soft-yellow{
  --el-color-white: #ffffcc!important;
  --el-notification-icon-color: #181818!important;
  --el-notification-title-color:#181818!important;
}


.notification-soft-yellow .el-icon{
  color: #181818 !important;
}

.notification-yellow{
  --el-color-white: #ffeb3b!important;
  --el-notification-icon-color: #181818!important;
  --el-notification-title-color:#181818!important;
}

.notification-yellow .el-icon{
  color: #181818 !important;
}


.notification-soft-green{
  --el-color-white: #ddffdd!important;
  --el-notification-icon-color: #181818!important;
  --el-notification-title-color:#181818!important;
}

.notification-soft-green .el-icon{
  color: #181818 !important;
}

.notification-green{
  --el-color-white: #4CAF50!important;
  --el-notification-icon-color: white!important;
  --el-notification-title-color:white!important;
}

.notification-green .el-icon{
  color: white !important;
}



.notification-soft-info{
  --el-color-white: #ddffff!important;
  --el-notification-icon-color: #181818!important;
  --el-notification-title-color:#181818!important;
}


.notification-soft-info .el-icon{
  color: #181818 !important;
}

.notification-info{
  --el-color-white: #2196F3!important;
  --el-notification-icon-color: white!important;
  --el-notification-title-color:white!important;
}

.notification-info .el-icon{
  color: white !important;
}

.el-notification__content{
  background:#ffffffa6 !important;
  color: rgb(0, 0, 0) !important;
  padding: 5px;
  border-radius: 5px;
  min-width: 255px;
}

.el-notification__title {
  font-weight: 700;
  font-size: var(--el-notification-title-font-size);
  line-height: var(--el-notification-icon-size);
  color: #1c1313;
  margin: 0;
}

.customFilter{
  margin-left: 1px;
  padding: 10px;
  background: white;
  text-align: center;
  margin-bottom: 4px;
  border-radius: 4px;
  color: white;
  box-shadow: 0px 0px 3px rgba(45, 45, 45, 0.5);
  cursor: pointer;
}

.all{
  background: var(--el-color-info);
}

.online{
  background: var(--el-color-success);
}

.offline{
  background: var(--el-color-danger);
}

.unknown{
  background: var(--el-color-warning);
}

.motion{
  background: var(--el-color-primary);
}


.customFilter.active{
  border: white 1px solid;
}

#btnmenu{
  display: none;
  padding: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  z-index: 1055; /* Más alto que el #head para asegurar que siempre sea interactivo */
  position: relative;
}

#expander{
  display: none;
  text-align: center;
  padding: 5px;
  margin-top: 20px; /* Añadido margen superior */
  background: #f3f3f3;
}

@media (orientation: portrait) {
  #menu{
    width: 0px;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 2.5rem; /* Ajustado a 2.5rem para coincidir con el nuevo header */
    z-index: 1010;
    border-radius: 0 16px 16px 0;
    box-shadow: 3px 0 20px rgba(0,0,0,0.2);
  }
  
  /* Ocultar la ciudad y ajustar estilos del clima en modo móvil */
  #weather .city-name {
    display: none;
  }
  
  #weather.header-info-box {
    padding: 0.2rem 0.3rem;
    margin: 0.2rem 0.3rem;
    font-size: 0.6rem;
  }
  
  /* Ajustar el logo en modo responsive */
  #logo {
    flex-shrink: 1;
    max-width: 35%;
    overflow: hidden;
  }
  
  #logo img {
    max-width: 100%;
  }
  
  /* Ajustar el contenedor de la derecha para maximizar espacio */
  .header-right-container {
    flex: 1;
    max-width: 60%;
  }
  
  /* Fijar el encabezado para que siempre sea visible en dispositivos móviles */
  #head {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 2.5rem; /* Aumentado a 2.5rem para dar más espacio */
    z-index: 1100; /* Asegurar que está por encima de todo */
  }
  
  /* Ajustar el contenido principal para compensar el encabezado fijo */
  #content {
    margin-top: 2.5rem; /* Aumentado a 2.5rem para coincidir con el header */
    padding-top: 10px;
  }

  #menu.isopen{
    width: 70px !important;
  }
  
  /* Esconder o menu em telas internas no mobile */
  body.mobile #menu {
    display: none;
  }
  
  body.mobile #menu.isopen {
    display: block;
  }

  #main{
    width: var(--vw,100vw);
    height: calc(var(--vh,100vh) - 2rem);
  }

  #main.menuShown{
    width: calc(var(--vw,100vw));
    filter: brightness(0.8);
  }

  /* Ajustar el contenedor del usuario para móvil */
  #head #user {
    padding: 0 0.2rem;
  }
  
  .uname {
    max-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  #btnmenu{
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--el-color-primary-light-3);
    border-radius: 6px;
    margin: 0.25rem;
    margin-top: 0.5rem; /* Aumentamos el margen superior para bajar el botón */
    width: 1.5rem;
    height: 1.5rem;
    transition: all 0.3s;
  }
  
  #btnmenu:hover, #btnmenu:active {
    background: var(--el-color-primary);
    color: white;
  }
  
  #btnmenu i {
    font-size: 1rem;
  }

  #open.shown{
    position: absolute;
    overflow: hidden;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1005;
    border-radius: 0;
    padding-top: 40px; /* Aumentado el padding superior para modo móvil */
  }

  #open.bottom{
    position: fixed;
    top: auto !important;
    bottom: 0px !important;
    height: 44vh;
    box-shadow: 0px -8px 15px rgba(0,0,0,0.15);
    border-radius: 20px 20px 0px 0px !important;
    overflow: hidden;
  }

  #open.bottom.mobileExpanded{
    height: calc(100vh - 100px) !important;
  }

  #open.bottom #heading{
    display: none !important;
  }

  #open.bottom .kr-spacer{
    display: none !Important;
  }

  #open.bottom #expander{
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background: var(--el-color-primary-light-5);
    border-radius: 0 0 16px 16px;
    margin-bottom: 5px;
  }
  
  #open.bottom #expander i {
    color: var(--el-color-primary-dark-2);
    transition: all 0.3s;
  }
  
  #open.bottom #expander:hover i {
    transform: scale(1.2);
  }

  #main.bottom{
    height: calc(55vh - 20px);
  }

  #pano{
    position: fixed !important;
    left: 0px !important;
    bottom: 0px;
    width: 100% !important;
    height: calc(44vh - 85px) !important;
    z-index: 1005 !important;
  }

  .el-dialog{
    --el-dialog-width: 100vw !important;
    border-radius: 16px !important;
  }

  .el-dialog__footer{
    overflow: auto;
    margin-right: 10px;
  }

  #menu ul {
    margin-top: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  #menu ul li {
    width: 70px;
    height: 70px;
    margin: 0.3rem 0;
    border-radius: 6px;
  }
  
  #menu ul li.active {
    background: var(--el-color-primary-light-1);
    border-left: 3px solid var(--el-bg-color);
  }
  
  #menu ul li a .text {
    font-size: 0.7rem;
    margin-top: 0.2rem;
  }
  
  #menu ul li a .el-icon {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
  }

  .showOnMobile{
    display: block !Important;
  }
  
  #version {
    display: none;
  }
}

.el-form-item{
  margin-bottom: 5px !important;
}

.el-form-item__label{
  line-height: 30px !important;
}

.leaflet-hiddenMarkers-pane{
  display: none;
}


.way-filtering .leaflet-hiddenMarkers-pane{
  display: block;
}


.way-filtering .leaflet-clusterMarkers-pane{
  display: none;
}

.way-filtering .marker-cluster{
  display: none;
}

/* Estilos para o cabeçalho */
.header-info-box {
  display: flex;
  align-items: center;
  padding: 0.2rem 0.5rem;
  margin: 0.2rem 0.5rem;
  font-size: 0.65rem;
  background: rgba(0,0,0,0.03);
  border-radius: 12px;
  transition: all 0.2s;
}

.header-info-box:hover {
  background: rgba(0,0,0,0.06);
}

.header-info-box .weather-icon {
  margin-right: 0.3rem;
  color: var(--el-color-primary);
}

.header-info-box .weather-text,
.header-info-box .time-text {
  margin: 0 0.2rem;
  color: var(--el-text-color-secondary);
}

.header-action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
  width: 1.8rem;
  height: 1.8rem;
  margin: 0 0.2rem;
  border-radius: 50%;
  transition: all 0.3s;
}

.header-action-btn:hover {
  background: rgba(0,0,0,0.05);
  transform: scale(1.1);
}

.push-notification-container {
  margin: 0 0.2rem;
}

</style>
