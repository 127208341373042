

export default {
    namespaced: true,
    state: () => ({
        integrationsList: []
    }),
    getters: {

    },
    mutations: {
        setIntegrations(state,value){
            state.integrationsList = value;
        },

        updateUser(state,value){
            const user = state.integrationsList.find((d)=>{
                return d.id === value.id;
            })

            if(user) {
                Object.assign(user, value);
            }

        }
    },
    actions: {
        load(context){
            return new Promise((resolve)=> {
                fetch("/tarkan/integrations").then((response)=>{
                    response.json().then((json)=>{
                        context.commit("setIntegrations",json);
                        resolve(json);
                    })
                });

            });
        },
        save(context,params){
            return new Promise((resolve)=> {
                fetch("/tarkan/integrations/"+params.key,{
                    "headers": {
                        "accept": "application/json, text/plain, */*",
                        "content-type": "application/json",
                    },
                    "body": JSON.stringify(params),
                    "method": "POST",
                }).then((response)=>{
                    response.json().then((json)=>{
                        context.commit("setIntegrations",json);
                        resolve(json);
                    })
                });
            });
        },

    }
}
