import { createApp } from 'vue'
import App from './App.vue'

//import { LMap, LTileLayer, LMarkerCluster, LLayerGroup } from "@vue-leaflet/vue-leaflet";
//import { LMap, LTileLayer, LLayerGroup } from "@vue-leaflet/vue-leaflet";
//import { LMarkerCluster} from "leaflet.markercluster";
import A from './license.js';
import Tarkan from './tarkan/tarkanConnector'
import Traccar from './tarkan/traccarConnector'
import store from "./store/index"

// Removida dependência do virtual scroller

import KT from './tarkan/func/kt'
import './registerServiceWorker'
import i18n from './lang/index'
import routes from './routes.js'

// Prevenir Font Awesome de reemplazar ícones con SVG y forzar el uso de clases CSS
// Esta configuración debe establecerse antes de cargar Font Awesome
window.FontAwesomeConfig = window.FontAwesomeConfig || {};
window.FontAwesomeConfig.autoReplaceSvg = false;
window.FontAwesomeConfig.styleDefault = 'solid';
window.FontAwesomeConfig.autoA11y = false;
window.FontAwesomeConfig.observeMutations = false;
window.FontAwesomeConfig.searchPseudoElements = false;
window.FontAwesomeConfig.keepOriginalSource = false;
window.FontAwesomeConfig.showMissingIcons = false;
window.FontAwesomeConfig.measurePerformance = false;

// Desactivar completamente el reemplazo automático SVG
if (window.FontAwesome) {
  window.FontAwesome.config = window.FontAwesomeConfig;
  window.FontAwesome.dom.i2svg = function() { return Promise.resolve(); };
  window.FontAwesome.dom.watch = function() {};
}


//import * as Sentry from "@sentry/vue";


//import ElementPlus from "element-plus";

//import "element-plus/dist/index.css";

//import 'leaflet/dist/leaflet.css';
//import 'leaflet.markercluster/dist/MarkerCluster.css';
//import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
//import 'leaflet.markercluster';

















const loc = window.location;

const serverUrl = loc.protocol + '//' + A[2] + '/api';
const tarkanUrl = loc.protocol + '//' + A[3] + '/tarkan';

window.$app = createApp(App).use(i18n).mixin({
  methods: {
    KT: KT
  }
}).use(store).use(Tarkan, tarkanUrl).use(Traccar, serverUrl).use(routes);
//window.$app.config.compilerOptions.isCustomElement = tag => tag.startsWith('LMarkerCluster');



window.$app.mount('#app');





/*


import ElInput from "element-plus/es/components/input";
import ElButton from "element-plus/es/components/button";
import ElDialog from "element-plus/es/components/dialog";
import {ElSelect,ElOption} from "element-plus/es/components/select";
import {ElTabs,ElTabPane} from "element-plus/es/components/tabs";

import ElDatePicker from "element-plus/es/components/date-picker";
import ElSwitch from "element-plus/es/components/switch";
import ElCheckbox from "element-plus/es/components/checkbox";
import ElSlider from "element-plus/es/components/slider";
import ElColorPicker from "element-plus/es/components/color-picker";
import {ElForm,ElFormItem} from "element-plus/es/components/form";

import ElUpload from "element-plus/es/components/upload"

import {ElDropdown,ElDropdownItem,ElDropdownMenu} from "element-plus/es/components/dropdown";

import ElRadio from "element-plus/es/components/radio";
import ElTooltip from "element-plus/es/components/tooltip";




import 'element-plus/es/components/button/style/css'
import 'element-plus/es/components/input/style/css'
import 'element-plus/es/components/dialog/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'
import 'element-plus/es/components/notification/style/css'
import 'element-plus/es/components/tabs/style/css'
import 'element-plus/es/components/tab-pane/style/css'
import 'element-plus/es/components/select/style/css'
import 'element-plus/es/components/option/style/css'
import 'element-plus/es/components/date-picker/style/css'
import 'element-plus/es/components/switch/style/css'
import 'element-plus/es/components/checkbox/style/css'
import 'element-plus/es/components/slider/style/css'
import 'element-plus/es/components/color-picker/style/css'
import 'element-plus/es/components/form-item/style/css'
import 'element-plus/es/components/upload/style/css'

import 'element-plus/es/components/dropdown/style/css'
import 'element-plus/es/components/dropdown-item/style/css'
import 'element-plus/es/components/dropdown-menu/style/css'

import 'element-plus/es/components/radio/style/css'
import 'element-plus/es/components/tooltip/style/css'

window.$app.use(ElInput);
window.$app.use(ElButton);
window.$app.use(ElDialog);
window.$app.use(ElOption);
window.$app.use(ElSelect);
window.$app.use(ElTabPane);
window.$app.use(ElTabs);
window.$app.use(ElDatePicker);
window.$app.use(ElSwitch);
window.$app.use(ElCheckbox);
window.$app.use(ElSlider);

window.$app.use(ElColorPicker);
window.$app.use(ElForm);
window.$app.use(ElFormItem);
window.$app.use(ElUpload);

window.$app.use(ElDropdown);
window.$app.use(ElDropdownItem);
window.$app.use(ElDropdownMenu);

window.$app.use(ElRadio);
window.$app.use(ElTooltip);
*/


