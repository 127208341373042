// routes.js - Módulo Vuex para gerenciar os dados das rotas
const routes = {
  namespaced: true,
  
  state: {
    routePositions: {}, // Objeto para armazenar os detalhes completos de cada posição por ID
    currentRoute: [], // Array de posições simplificadas para exibição no mapa
    isLoading: false
  },
  
  getters: {
    // Obter todos os pontos da rota atual
    getRoutePoints: state => state.currentRoute,
    
    // Obter posição específica pelo ID
    getPositionById: state => id => state.routePositions[id] || null,
    
    // Obter detalhes completos da posição atual
    getCurrentPosition: (state, getters, rootState) => {
      const index = rootState.devices.routePlayPoint || 0;
      if (state.currentRoute.length === 0 || index >= state.currentRoute.length) {
        return null;
      }
      
      const pointId = state.currentRoute[index][2]; // O ID está na posição 2 do array
      return getters.getPositionById(pointId);
    },
    
    // Status de carregamento
    isLoading: state => state.isLoading
  },
  
  mutations: {
    // Definir os pontos da rota atual (formato simplificado para o mapa)
    SET_ROUTE_POINTS(state, points) {
      state.currentRoute = points;
    },
    
    // Adicionar uma posição detalhada ao objeto de posições
    ADD_POSITION(state, position) {
      // Garantir que posições com o mesmo ID sejam sobrescritas
      state.routePositions = {
        ...state.routePositions,
        [position.id]: position
      };
    },
    
    // Adicionar múltiplas posições detalhadas
    ADD_POSITIONS(state, positions) {
      const positionsMap = {};
      positions.forEach(position => {
        positionsMap[position.id] = position;
      });
      
      state.routePositions = {
        ...state.routePositions,
        ...positionsMap
      };
    },
    
    // Limpar as posições armazenadas
    CLEAR_POSITIONS(state) {
      state.routePositions = {};
      state.currentRoute = [];
    },
    
    // Definir estado de carregamento
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    }
  },
  
  actions: {
    // Carregar rota a partir da API
    async loadRoute({ commit }, { deviceId, startDate, endDate, isExport = false }) {
      commit('SET_LOADING', true);
      
      try {
        const $traccar = window.$traccar;
        const response = await $traccar.loadRoute(deviceId, startDate, endDate, isExport);
        
        if (!isExport) {
          // Armazenar os dados completos das posições
          commit('ADD_POSITIONS', response.data);
          
          // Preparar formato simplificado para o mapa
          const simplifiedPoints = response.data.map(p => [
            p.latitude, 
            p.longitude, 
            p.id, 
            p.course
          ]);
          
          // Atualizar pontos de rota
          commit('SET_ROUTE_POINTS', simplifiedPoints);
          
          // Atualizar a rota no mapa
          return {
            data: response.data,
            points: simplifiedPoints
          };
        }
        
        return response;
      } catch (error) {
        console.error("Erro ao carregar rota:", error);
        throw error;
      } finally {
        commit('SET_LOADING', false);
      }
    },
    
    // Limpar dados da rota
    clearRoute({ commit }) {
      commit('CLEAR_POSITIONS');
    },
    
    // Obter endereço de uma posição específica
    async loadAddress({ commit, getters }, positionId) {
      const position = getters.getPositionById(positionId);
      
      if (!position || position.address) {
        return position; // Se já tem endereço ou não existe, retorna a posição atual
      }
      
      try {
        // Implementar chamada à API de geocodificação ou usar a API do Tarkan
        // Esta é uma simulação de chamada - em produção, usar uma API real
        
        // Verificar se o traccar tem uma API para obter o endereço
        // ou usar Google/OSM/etc
        
        const address = await new Promise(resolve => {
          setTimeout(() => {
            // Simulação de resposta da API
            resolve(`Endereço na latitude ${position.latitude}, longitude ${position.longitude}`);
          }, 500);
        });
        
        // Atualizar a posição com o endereço
        const updatedPosition = {
          ...position,
          address
        };
        
        commit('ADD_POSITION', updatedPosition);
        return updatedPosition;
      } catch (error) {
        console.error("Erro ao carregar endereço:", error);
        return position;
      }
    }
  }
};

export default routes;