const messages = {
    version: '1.12.7',
    HTTP_401_UNAUTHORIZED: 'Combinação de usuário e senha inválidos.',
    ACCOUNT_IS_SUSPENDED: 'Sua conta está suspensa por falta de pagamento. Por favor entre em contato com o suporte ou regularize seus débitos.',
    ACCOUNT_IS_SUSPENDED_TRUST: 'Sua conta está suspensa por falta de pagamento, mas não se preocupe, você pode realizar o desbloqueio de confiança antes de regularizar seus débitos.',
    ACCOUNT_IS_DISABLED: 'Esta conta foi desabilitada, contate o administrador.',
    errors: {
            USER_DEVICE_LIMIT_REACHED: 'User device limit reached',
            ACCOUNT_IS_READONLY: 'Account is ReadyOnly',
            action_cancel: 'Action Cancel',
            ACCOUNT_IS_DEVICE_READONLY: 'Account is Device ReadyOnly'
        },
        NEW_VERSION_AVAILABLE:'NEW VERSION AVAILABLE',
        ALL_TASK_READY: 'All tasks completed successfully.',
        FILL_ALL_FIELDS: 'Please fill in all fields.',
        INVALID_LOGIN_DATA: 'Sorry, it looks like you entered incorrect data.',
        NO_DATA_TEXT: 'No data available',
        NO_MATCH_TEXT: 'Not Found',
        output_type: 'Output Type',
        output_type_text: 'Text',
        output_type_Numeric: 'Numeric',
        output_type_Logical: 'Logical',
        expression: 'Expression',
        Please_input: 'Please input',
        Atribut: 'Atribute',
        descriptionCommand: 'Description',
        Command_2: 'Command',
        do_not_test: 'Do not test',
        Test_Attribute_on: 'Test attribute on',
        Outcome: 'Outcome',
        active_text: 'Apply this attribute to my favorites',
        devices: 'devices',
        Attribute_Registry: 'Computed attribute registered with success.',
        Binding_attribute_device: 'Binding attribute to device',
        registry_attribute: 'Registering your attribute...',
        computed_attribute_deleted: 'Computed attribute deleted as event.',
        computed_attribute_deleted_confirmation: 'Do you really want to exclude this computed attribute? Are you sure?',
        LOADING: 'Loading...',
        route: {
            start: 'Route Start',
            end: 'Route End',
            empty: 'No route for selected period.'
        },
        sorting:{
            'name-asc': 'Name',
            'name-desc': 'Name',
            'lastUpdate-asc': 'Last Updated',
            'lastUpdate-desc': 'Last Updated',
            'state-motion': 'Motion',
            'state-anchor': 'Anchor',
            'state-locked': 'Locked',
            'state-ignition': 'Ignition',
            'state-driver': 'Driver',
            'state-alert': 'Alert'
        },
        customize: 'Customize',
        preferences: 'Preferences',
        search: 'Search',
        page: 'Page',
        now: 'Now',
        days: 'days',
        hours: 'hours',
        minute: 'minute',
        minutes: 'minutes',
        seconds: 'seconds',
        lessMinute: '< 1 minute',
        yes: 'Yes',
        no: 'No',
        new: 'New',
        all: 'All',
        online: 'On-line',
        offline: 'Offline',
        unknown: 'Unknown',
        rememberme: 'Remember me',
        enabled: 'Enabled',
        disabled: 'Disabled',
        "fixed": "Fixado",
        "percent": "Porcentagem",
        always: 'Sempre',
        automatic: 'Automatic',
        set: 'As defined',
        unlimited: 'Unlimited',
        ok: 'OK',
        save: 'Save',
        cancel: 'Cancel',
        delete: 'Delete',
        danger: 'Warning!',
        info: 'Please wait...',
        success: 'Success!',
        sure: 'Are you sure?',
        ops: 'Ops!',
        userCancel: 'Operation canceled by user.',
        today: 'Today',
        yesterday: 'Yesterday',
        thisweek: 'Last 7 days',
        thismonth: 'Last 30 days',
        threemonths: 'Last 90 days',
        startDate: 'Start Date',
        endDate: 'End Date',
        to: 'to',
        weekDays: {
          SU: 'Sun',
          MO: 'Mon',
          TU: 'Tue',
          WE: 'Wed',
          TH: 'Thu',
          FR: 'Fri',
          ST: 'Sat'
        },
        units: {
            currency: (ctx) =>{
                return "$ "+parseFloat(ctx.named('value')).toFixed(2);
            },
            speedUnit: (ctx)=>{
                return Math.round(ctx.named('speed')*1.15078)+' mph/h';
            },
            distanceUnit: (ctx)=>{
                return ((ctx.named('distance')/1000)*0.621371).toFixed(2)+' mi';
            },
            miles: (ctx)=>{
                return ((ctx.named('distance')/1000)*0.621371).toFixed(2)+' mi';
            },
            km: (ctx)=>{
                return (ctx.named('distance')/1000).toFixed(2)+' km';
            },
            mph: (ctx)=>{
                return Math.round(ctx.named('speed')*1.15078)+' mph/h';
            },
            kmh: (ctx)=>{
                return Math.round(ctx.named('speed')*1.852)+' km/h';
            },
            kn: (ctx)=>{
                return ctx.named('speed')+' kn';
            },
            ignition: (ctx)=>{
                return (ctx.named('value'))?'Yes':'No';
            },
            blocked: (ctx)=>{
                return (ctx.named('value'))?'Yes':'No';
            },
            motion: (ctx)=>{
                return (ctx.named('value'))?'Yes':'No';
            },
            valid: (ctx)=>{
                return (ctx.named('value'))?'Yes':'No';
            },
            distance: (ctx)=>{
                return (ctx.named('value')/1000).toFixed(2)+' km';
            },
            totalDistance: (ctx)=>{
                return (ctx.named('value')/1000).toFixed(2)+' km';
            },
            speed: (ctx)=>{
                return Math.round(ctx.named('value')*1.852)+' km/h';
            },
            serverTime: (ctx)=>{
                return new Date(ctx.named('value')).toLocaleString();
            },
            deviceTime: (ctx)=>{
                return new Date(ctx.named('value')).toLocaleString();
            },
            fixTime: (ctx)=>{
                return new Date(ctx.named('value')).toLocaleString();
            }
        },
        menu: {
            autoLink: 'Add new device',
            devices: 'Devices',
            history: 'History',
            reports: 'Reports',
            geofence: 'Geofence',
            commands: 'Commands',
            groups: 'Groups',
            notifications: 'Notifications',
            reportsHistory: 'History Report',
            reportsResume: 'Resume Report',
            reportsEvents: 'Events Report',
            reportsStops: 'Stops Report',
            reportsTravels: 'Travels Report',
            computedAttributes: 'Computed Attributes'
        },
        usermenu:{
            account: 'My Account',
            users: 'Users',
            theme: 'Theme Customization',
            logs: 'Advanced Logs',
            integrations: 'Integrações',
            invoices: 'Minhas Faturas',
            billing: 'Gestão de Cobranças',
            reseller: 'Reseller',
            computedAttributes: 'Computed Attributes',
            server: 'Server',
            notifications: 'Notifications',
            drivers: 'Drivers',
            calendars: 'Calendars',
            maintenance: 'Maintenance',
            logout: 'Logout'
        },
        theme:{
            edit: 'Edit Theme',
            general: 'General',
            login: 'Login Screen',
            logos: 'Logos',
            icon: 'Icon',
            generalColors: 'General Colors',
            textColors: 'Text Colors',
            otherColors: 'Other Colors',
            appName: 'Service Name',
            whatsapp: 'WhatsApp',
            internalLogo: 'Internal Logo',
            logoAsImage: 'Show as image',
            logoAsText: 'Show as text',
            filterColor: 'Filter Color',
            backgroundImage: 'Background Image',
            logoOnLogin: 'On Login Screen',
            logoOnHead: 'Internal Logo',
            logoSize: 'Logo Size',
            backgroundColor: 'Background Color',
            generalWhite: 'General Light',
            primary: 'Primary',
            textColor: 'Text Color',
            generalBlack: 'General Dark',
            variant1: 'Primary Variant 1',
            variant2: 'Primary Variant 2',
            variant3: 'Primary Variant 3',
            variant4: 'Primary Variant 4',
            variant5: 'Primary Variant 5',
            variant6: 'Primary Variant 6',
            variant7: 'Primary Variant 7',
            variant8: 'Primary Variant 8',
            variant9: 'Primary Variant 9',
            textPrimary: 'Text Primary',
            textRegular: 'Text Regular',
            textSecondary: 'Text Secondary',
            textWhite: 'Text Light',
            success: 'Success',
            successVariant1: 'Success Variant 1',
            successVariant2: 'Success Variant 2',
            alert: 'Alert',
            alertVariant1: 'Alert Variant 1',
            alertVariant2: 'Alert Variant 2',
            danger: 'Danger',
            dangerVariant1: 'Danger Variant 1',
            dangerVariant2: 'Danger Variant 2',
            info: 'Info',
            infoVariant1: 'Info Variant 1',
            infoVariant2: 'Info Variant 2'
        },
        report: {
            selectType: 'Select report type',
            history: 'History',
            resume: 'Resume',
            stops: 'Stops',
            travels: 'Travels',
            events: 'Events',
            load: 'Load',
            export: 'Export',
            showMarkers: 'Show Markers'
        },
        alarms: {
            none: 'No Alert',
            alarm: 'Alarm',
            alarms: 'Alarms',
            general: 'General',
            sos: 'SOS',
            vibration: 'Vibration',
            lowspeed: 'Low Speed',
            overspeed: 'Over Speed',
            movement: 'Moving',
            fallDown: 'Fall Down',
            lowPower: 'Low Power',
            lowBattery: 'Low Battery',
            fault: 'Fault',
            powerOff: 'Power Off',
            powerOn: 'Power On',
            door: 'Door',
            unlock: 'Unlock',
            lock: 'Lock',
            geofence: 'Geofence',
            geofenceEnter: 'Geofence Enter',
            geofenceExit: 'Geofence Exit',
            gpsAntennaCut: 'GPS Cut',
            accident: 'Accident',
            tow: 'Tow',
            idle: 'Idle',
            highRpm: 'High RPM',
            hardAcceleration: 'Hard Acceleration',
            hardBraking: 'Hard Braking',
            hardCornering: 'Hard Cornering',
            laneChange: 'Lane Change',
            fatigueDriving: 'Fatigue Driving',
            powerCut: 'Power Cut',
            powerRestored: 'Power Restored',
            jamming: 'Jamming',
            temperature: 'Temperature',
            parking: 'Parking',
            shock: 'Shock',
            bonnet: 'Bonnet',
            footBrake: 'Foot Brake',
            fuelLeak: 'Fuel Leak',
            tampering: 'Tampering',
            removing: 'Removing'
        },
        graph:{
            graphicType: 'Chart Type'
        },
        integrations:{
            title: 'Integrações',
            enable: 'Habilitar',
            configure: 'Configurar',
            asaas: {
                title: 'Asaas',
                inputs: {
                    email: 'Email',
                    token: 'Token',
                    sandbox: 'Habilitar Ambiente de Teste'
                }
            },
            email:{
                title: 'E-Mail',
                inputs: {
                    username: 'Nome de Usuário',
                    password: 'Senha',
                    mailfrom: 'E-mail remetente',
                    mailfrom_name: 'Nome do Remetente',
                    smtp_host: 'Host SMTP',
                    smtp_port: 'Porta SMTP'
                }
            }
        },
        notification:{
            autoAdd: 'Automatically add to new users',
            test: 'Test Notification',
            testSent: 'Test Notification was sent succesfully',
            search: 'Search Notification',
            deleted: 'Notification successfully deleted.',
            selectError: 'You need to select a notification to edit.',
            saveError: 'Unable to save your notification.',
            confirmDelete: 'You are deleting a notification of "%type%", do you want to continue?',
            saving: 'Saving your notification...',
            saved: 'Your notification has been successfully saved.',
            linkToDevice: 'Assigning to device "%name%"',
            linkToGroup: 'Assigning to group "%name%"',
            linkToUser: 'Assigning user "%name%"',
            title: 'Manage Notifications',
            notification: 'Notification',
            notifications: 'Notifications',
            newNotification: 'New Notification',
            editNotification: 'Edit Notification',
            add: 'Add',
            remove: 'Remove',
            edit: 'Edit',
            type: 'Type',
            all: 'All Devices',
            alarms: 'Alarms',
            channel: 'Channels',
            customize: 'Customize',
            position: 'Position',
            color: 'Color',
            sound: 'Notification Sound',
            soundURL: 'Custom URL (mp3,wav,ogg)',
            pin: 'Pin Notification',
            sounds: {
              mute: 'Mute',
                custom: 'URL',
                audio1: '1 - Apple',
                audio2: '2 - Huawei',
                audio3: '3 - Huawei Tone',
                audio4: '4 - Apple Tone',
                audio5: '5 - Huaei Cave',
                audio6: '6 - Apple Pay',
                audio7: '7 - Bright',
                audio8: '8 - Crosswalk',
                audio9: '9 - Crosswalk Japan',
                audio10: '10 - Define',
                audio11: '11 - iPhone Ding',
                audio12: '12 - Netflix',
                audio13: '13 - Coin',
                audio14: '14 - Switch',
                audio15: '15 - Sonic Rings',
                audio16: '16 - Police Alarm',
                audio17: '17 - Police Tune',
                audio18: '18 - Police Woop',
                audio19: '19 - Police Notification',
                audio20: '20 - Police',
                audio21: '21 - Claro',
                audio22: '22 - Whistle',
                audio23: '23 - S8/NOTE',
                audio24: '24 - Alarm'
            },
            colors: {
              'soft-red': 'Light Red',
              'red': 'Red',
              'soft-green': 'Light Green',
              'green': 'Green',
              'soft-yellow': 'Light Yellow',
              'yellow': 'Yellow',
              'soft-info': 'Light Blue',
              'info': 'Blue'
            },
            positions: {
              'top-right': 'Top Right',
              'top-left': 'Top Left',
              'bottom-right': 'Bottom Right',
              'bottom-left': 'Bottom Left'
            },
            types: {
                alarm: 'Alarms',
                deviceOnline: 'Device Online',
                deviceOffline: 'Device Offline',
                textMessage: 'Text Message',
                deviceUnknown: 'Device Unknown',
                geofenceExit: 'Geofence Exit',
                geofenceEnter: 'Geofence Enter',
                ignitionOff: 'Ignition Off',
                ignitionOn: 'Ignition On',
                deviceMoving: 'Device Moving',
                deviceStopped: 'Device Stopped',
                deviceOverspeed: 'Device OverSpeed',
                commandResult: 'Command Result',
                driverChanged: 'Driver Changed',
                deviceFuelDrop: 'Device Fuel Drop',
                maintenance: 'Maintenance',
                deviceInactive: 'Device Inactive',

            },
            channels:{
                web: 'Web',
                firebase: 'Celular',
                telegram: 'Telegram',
                sms: 'SMS',
                mail: 'E-Mail'
            },
            messages:{
                test: 'Test Notification',
                alarm: 'Alarm',
                deviceOnline: 'Device Online',
                deviceOffline: 'Device Offline',
                textMessage: 'Text Message',
                deviceUnknown: 'Device Unknown',
                geofenceExitName: 'Geofence Exit: %name%',
                geofenceExit: 'Geofence Exit.',
                geofenceEnterName: 'Geofence Enter: %name%',
                geofenceEnter: 'Geofence Enter',
                ignitionOff: 'Ignition Off',
                ignitionOn: 'Ignition On',
                deviceMoving: 'Device Moving',
                deviceStopped: 'Device Stopped',
                deviceOverspeed: 'Device Over Speed',
                commandResult: 'Command Result: %result%',
                driverChanged: 'Driver Changed',
                deviceFuelDrop: 'Fuel Drop',
                maintenance: 'Maintenance',
                deviceInactive: 'Device Inactive'
            }
        },
        login: {
            username: 'Username',
            password: 'Password',
            your_email: 'your username',
            your_password: 'your password',
            signin: 'Sign-in',
            suspended: 'Conta Suspensa',
            lost_password: 'Recuperar Senha',
            lost_send: 'Solicitação de senha enviada, em instantes você receberá um e-mail de confirmação.',
            password_changed: 'Sua senha foi modificada com sucesso, agora você pode realizar o login com sua nova senha.',
            register: 'Cadastrar',
        },
        actions: {
            custom: 'Custom Command',
            engineStop: 'Engine Stop',
            engineResume: 'Engine Resume',
            anchorEnable: 'Enable Anchor',
            anchorDisable: 'Disable Anchor',
            deviceIdentification: 'Device Identification',
            positionSingle:'Position Single',
            alarmArm:'Alarm Arm',
            alarmDisarm:'Alarm Disarm',
            outputControl:'Output Control',
            rebootDevice:'Reboot Device',
            offline:'Offline'
        },

        invoice:{
            id: 'ID',
            dueDate: 'Data de Vencimento',
            value: 'Valor',
            status: 'Status',
            paidDate: 'Data de Pagamento',
            count: 'Total de Cobranças',
            pending: 'Pendente',
            paid: 'Pago',
            view: 'Ver Faturas',
            add: 'Adicionar Fatura',
            delete: 'Cancelar Fatura',
            unlock: 'Desbloqueio',
            trustUnlock: 'Desbloqueio de Confiança',
            lock: 'Suspender Acesso',

            alreadyTrust: 'Você já usou o desbloqueio de confiança.',

            confirmDelete: 'Deseja realmente realizar o cancelamento desta fatura?',
            confirmDeleteSuccess: 'Fatura cancelada com sucesso!',

            confirmUnlock: 'Deseja realmente realizar o desbloqueio de confiança para o usuário %name%?',
            confirmUnlockSuccess: 'Usuário desbloqueado com sucesso.',

            confirmLock: 'Deseja realmente realizar a suspensão de conta para o usuário %name%?',
            confirmLockSuccess: 'Usuário suspenso com sucesso.',
            confirmCash: 'Deseja realmente marcar a fatura como recebida?',
            confirmCashSuccess: 'Operação realizada com sucesso.',
            receivedCash: 'Marcar como Recebido em Dinheiro',
            statuses: {
                PENDING: 'Aguardando Pagamento',
                RECEIVED_IN_CASH: 'Recebido em Dinheiro',
                RECEIVED: 'Recebido',
                CONFIRMED: 'Confirmado',
                OVERDUE: 'Vencido',
                REFUND_REQUESTED: 'Estorno Solicitado',
                REFUND_IN_PROGRESS: 'Estorno em Progresso',
                CHARGEBACK_REQUESTED: 'Chargeback Solicitado',
                CHARGEBACK_DISPUTE: 'Em Disputa',
                AWAITING_CHARGEBACK_REVERSAL: 'Disputa Vencida',
                DUNNING_REQUESTED: 'Em processo de negativação',
                DUNNING_RECEIVED: 'Recuperada',
                AWAITING_RISK_ANALYSIS: 'Aguardando Análise de Risco'
            }
        },
        user:{
            billing: "Faturamento",
            billingEnable: 'Habilitar Faturamento',
            billingCpfCnpj: "CPF/CNPJ",
            billingTrustUnlock: 'Permitir desbloqueio de confiança',
            billingTrustUnlockDays: 'Quantos dias de confiança?',
            billingSuspension: "Habilitar Suspensão Automática",
            billingSuspensionDays: "Aguardar quantos dias após o vencimento antes da suspensão",
            billingDescription: "Descrição da Cobrança",
            billingDate: "Dia do Primeiro Vencimento",
            billingPrice: "Valor da Fatura",
            billingFine: "Valor da Multa",
            billingFineMode: "Modo da Multa",
            billingInterest: "Juros",
            billingDisccount: "Desconto",
            billingDisccountMode: "Modo de Desconto",
            billingDisccountDays: "Quantos dias antes do vencimento para o desconto",
            accountInfo: 'Account Details',
            preferences: 'User Preferences',
            permissions: 'User Permissions',
            new: 'New user',
            device: 'Device',
            expirationTime: 'Expiration date',
            shareLink: 'Share Link',
            logs: 'Logs',
            users: 'Users',
            user: 'User',
            search: 'Search User',
            add: 'New User',
            edit: 'Edit User',
            remove: 'Delete User',
            title: 'Manage Users',
            id: 'Id',
            name: 'Name',
            phone: 'Phone',
            email: 'E-Mail',
            password: 'Password',
            "passwordConfirm": "Confirmar senha",
            admin: 'Administrator',
            disabled: 'Disabled',
            language: 'Language',
            map: 'Map',
            latitude: 'Latitude',
            longitude: 'Longitude',
            twelveHourFormat: 'Twelve Hour Format Clock',
            zoom: 'Zoom',
            coordinateFormat: 'Coordinate Format',
            userStatus: 'User Status',
            readOnly: 'Read Only',
            editDevices: 'Edit Devices',
            limitCommands: 'Send Commands',
            expirationDate: 'Expiration Date',
            deviceLimit: 'Limit Devices',
            userLimit: 'Limit Users',
            token: 'Access Token',
            error:{
                save: 'Error!',
                checkForm: 'Please check your form data.',
                superior: 'You can\'t edit a user that is superior than you.',
                select: 'Please select an user to edit.',
                USER_NOT_AUTHED: 'Update your page',
                WRITE_ACCESS_DENIED:'ACCESS DENIED'
            },
                form: {
                    userNameEmpty: 'Please input a name and lastname',
                    userNameLength: 'Minimum 3 characters',
                    emailEmpty: 'Please input user e-mail',
                    emailLength: 'Minimum 4 characters expected',
                    passwordEmpty: 'Please input a password',
                    passwordLength: 'Minimum 4 characters expected'
                  },
            advanced:{
                reseller: 'Reseller',
                subReseller: 'You can create new resellers',
                domainReseller: 'Reseller Domain',
                limitReseller: 'resale limit',
                lock: 'Lock',
                unlock: 'Unlock',
                command: 'Send commands',
                create: 'Create',
                edit: 'Edit',
                delete: 'Delete',
                self: 'Can be edited',
                devices: 'Devices',
                reports: 'Reports',
                geofences: 'Geofences',
                commands: 'Commands',
                groups: 'Groups',
                notifications: 'Notifications',
                drivers: 'Drivers',
                calendar: 'Calnedar',
                anchor: 'Anchor',
                share: 'Share',
                users: 'Users',
                attributes: 'Computed Attributes',
                calendars: 'Calendars',
                maintenance: 'Maintenance',
            }
        },
      
        map:{
            showGroups: 'Show Groups',
            showGeofences: 'Show Geofences',
            showNames: 'Show Names',
            showPlates: 'Show Plates',
            showPrecision: 'Show Precision',
            showStatus: 'Show Status',
            devices:{ 
                default: 'Default',
                arrow: 'Arrow',
                person: 'Person',
                animal: 'Animal',
                bicycle: 'Bicycle',
                motorcycle: 'Motorcycle',
                scooter: 'Scooter',
                car: 'Car',
                pickup: 'Pick-Up',
                van: 'Van',
                truck: 'Truck',
                truck1: 'Truck 1',
                truck2: 'Truck 2',
                tractor: 'Tractor',
                boat: 'Boat',
                ship: 'Ship',
                bus: 'Bus',
                train: 'Train',
                trolleybus: 'Trolley Bus',
                tram: 'Tram',
                crane: 'Crane',
                plane: 'Plane',
                helicopter: 'Helicopter',
                offroad: 'Offroad'
            }
        },
        device:{
            noDriver: 'No Driver',
            anchorDisabled: 'Anchor Disabled',
            anchorEnabled: 'Anchor Enabled',
            odometer: 'Odometer (in meters)',
            category: 'Category',
            motion: 'Movendo',
            stopped: 'Parado',
            lastStoppedTime: 'Stopped',
            loading: 'Device is loading...',
            driver: 'Driver',
            isQrDriverId: 'Driver identified by QRCode',
            showQrCode: 'QrCode Driver ',
            shareLink: 'Share temporary access',
            shareMaps: 'Share on GoogleMaps',
            shareStreet: 'Share StreetView',
            openMaps: 'Google Maps',
            openStreet: 'Street View',
            saveError: 'Unable to save your device.',
            confirmDelete: 'You are deleting a device "%name%", do you want to continue?',
            saving: 'Saving your device...',
            saved: 'Operation performed successfully.',
            attributions: 'Attributions',
            title: 'Manage Devices',
            devices: 'Devices',
            device: 'Device',
            logs: 'Logs',
            share: 'Share',
            openExternal: 'Open in',
            routes: 'Routes',
            graphic: 'Graphic',
            startOdometer: 'Start Odometer',
            endOdometer: 'End Odometer',
            averageSpeed: 'Avg. Speed',
            maxSpeed: 'Max Speed',
            engineHours: 'Engine Hours',
            fuel: 'Fuel',
            spentFuel: 'Spent Fuel',
            currency: 'currency',
            fuelPrice: 'Fuel Price',
            litersx100km: 'Unit fuel x 100 Km',
            duration: 'Duration',
            battery: 'Battery',
            batteryLevel: 'Battery Level',
            ignitionOn: 'Ignition On',
            ignitionOff: 'Ignition Off',
            blocked: 'Blocked',
            unblocked: 'Unblocked',
            "rssi": 'rssi',
            network:'network',
            sat:'sat',
            sattelites:'sattelites',
            power:'power',
            alarm:'alarm',
            alarms:'alarms',
            unknown:'unknown',
            disabled:'disabled',
            new:'new',
            moving: 'Moving',
            stoped: 'Stopped',
            noPosition: 'No Position',
            lastPosition: 'Last Position',
            updatedAt: 'Updated At',
            positionSpeed: 'Speed',
            distance: 'Total Distance',
            online:'online',
            offline:'offline',
            lastPositionTime:'Last Position Time',
            chip: 'Chip',
            follow: 'Follow',
            unfollow: 'Unfollow',
            trail: 'Show Trail',
            untrail: 'Hide Trail',
            zoom: 'Zoom',
            details: 'Details',
            lock: 'Stop Engine',
            unlock: 'Resume Engine',
            send_command: 'Send Command',
            confirm_lock: 'Do you really want to stop engine the device ',
            questionBlokedo: 'Do you really want to stop engine to ',
            confirm_unlock: 'Do you really want to resume engine the device ',
            confirm_command: 'You are sending a custom command to "%name%", some commands may harm the system! Do you wish to continue?',
            command_sent: 'Command sent successfully.',
            edit: 'Edit Device',
            add: 'New Device',
            remove: 'Delete',
            search: 'Search',
            updated: 'Updated',
            imei: 'Device Unique Identificator (IMEI)',
            name: 'Device Name',
            status: 'Status',
            iccid: 'ICCID',
            phone: 'Phone',
            operator: 'Operator',
            model: 'Model',
            plate: 'Plate',
            color1: 'Primary Color',
            color2: 'Secundary Color',
            icon: 'Select your icon',
            hue: 'Color',
            saturate: 'Saturate',
            brightness: 'Brightness',
            historyEvents: 'History Events',
            historyPosition: 'History Position',
            form:{
                nameEmpty: 'Please input a device name',
                uniqueIdEmpty: 'Please input a device unique id',
            },
            streetview: 'StreetView',
            questionBlocked: 'Do you really want to block the vehicle?',
    success: 'Success',
    action_cancel: 'Action canceled by the user',
    questionUnblocked: 'Do you really want to unblock the vehicle?',
    question_del1: 'Do you really want to delete the vehicle',
    question_del2: 'Are you sure?',
    info: 'Info',
    deviceDeleted: 'Device deleted successfully.',
    error_device_delete: 'An unexpected error occurred while deleting your device.',
    error: 'Error'
},
log: {
    Mess_Carga: 'Loading records, please wait...',
    Log: 'Log',
    Device_acceso: 'Access Device',
    Mess_log_ok: 'Successful login attempt.',
    Mess_log_out: 'Failed login attempt.',
    Geocerca: 'Geofence',
    edit: 'edited:',
    Mess_user_edit: 'Failed attempt to delete user.',
    Link_Share_add: 'Link sharing created.',
    Link_Share_out: 'Failed attempt to share link.',
    Link_Share_edit: 'Link sharing edited.',
    Link_Share_edit_err: 'Failed attempt to edit link sharing.',
    usser_motor_add: 'Driver access user created.',
    usser_motor_out: 'Failed attempt to create driver access.',
    send_comand: 'Command sent.',
    send_comand_err: 'Failed to send command.',
    Blocked_motorista: 'Blocked by driver rule.',
    Unblocked_motorista: 'Unblocked by driver rule.',
    edit_device_err: 'Failed attempt to edit vehicle.',
    device: 'Device',
    excluido: 'deleted',
    falla: 'failed',
    anchor_activ: 'Anchor activated',
    ancora_err: 'Failed attempt to activate anchor.',
    geofence_add: 'Geofence created.',
    geofence_out: 'Failed attempt to create geofence.',
    ancora_aut: 'Anchor deactivated.',
    ancora_desact_err: 'Failed attempt to deactivate anchor.',
    deleted: 'removed from',
    geofence_out_err: 'Failed attempt to remove geofence.',
    geofence_out_ok: 'Geofence removed.',
    idem: 'unchanged.'
},
        commands: {
            command: 'Command',
            search: 'Search Commmand',
            title: 'Title',
            type: 'Type',            
            new: 'New Command',
            edit: 'Edit Command',
            savedCommands: 'Saved Commands',
            confirmDelete: 'Do you want to delete this command?',
            changeNative: 'Change native command action',
            changeAction: 'Native action to change'
        },
        attribute:{
            attribute: 'Attribute',
            value: 'Value',
            add: 'Add Attribute',
            title: 'Manage Attributes',
            attributes: 'Attributes',
            computedAttributes: 'Computed Attributes',
            search: 'Search Attribute',
            id: 'ID',
            batteryLevel: 'Battery Level',
            battery: 'Battery',
            power: 'Power',
            alarms:'alarms',
            totalDistance: 'Total Distance',
            deviceId: 'Device ID',
            protocol: 'Protocol',
            serverTime: 'Server Time',
            deviceTime: 'Device Time',
            rssi:'rssi',
            raw: 'Raw',
            adc1:'Tension Battery',
            out:'out',
            out1:'out1',
            out2:'out2',
            out3:'out3',
            out4:'out4',
            in: 'In',
            in1: 'in1',
            in4: 'in4',
            in2: 'in2',
            in3: 'in3',
            fixTime: 'Fix Time',
            valid: 'Valid',
            spentFuel:'Spent Fuel',
            fuel:'Fuel',
            outdated: 'out dated',
            type:'Type',
            versionFw:'Version Fw',
            status:'Estado',
            index:'Index',
            priority:'priority',
            io200:'io200',
            io69:'io69',
            pdop:'pdop',
            hdop:'hdop',
            io24:'io24',
            io9:'io9',
            io6:'io6',
            operator:'operator',
            driverUniqueId:'driverUniqueId',
            ip:'Ip',
            latitude: 'Latitude',
            longitude: 'Longitude',
            altitude: 'Altitude',
            address: 'Address',
            accuracy: 'Accuracy',
            speed: 'Speed',
            odometer: 'Odometer',
            hours: 'Hours',
            distance: 'Distance',
            course: 'Course',
            motion: 'Motion',
            alarm: 'Alarm',
            event: 'Event',
            sat: 'Satelittes',
            blocked: 'Blocked',
            ignition: 'Ignition',
            network: 'Network',
            speedLimit: 'Speed ​​limit',
            lockOnExit: 'Blocking When Leaving the Fence',
            telegramChatId: 'Telegram ID',
            currency: 'currency',
            fuelPrice: 'Fuel Price',
            fuelCost: 'Fuel Cost',
            litersx100km: 'Unit fuel x 100 Km',
            notificationTokens: 'Notification Tokens',
            "volumeUnit": "Volume Unit",
            'distanceUnit':"Distance Unit",
            'speedUnit':'Speed Unit',
            'google_api':"API KEY Google"
        },
        editAttribute: {
            attributes_att: 'attributes',
            attributes_save: 'Computed attribute registered successfully.',
            attributes_vincu: 'Linking attribute to device ',
            attributes_Add: 'Registering your attribute...',
            attributes_Del: 'Computed attribute deleted successfully.',
            attributes_Question1: 'Do you really want to delete this computed attribute?',
            attributes_Question2: 'Are you sure?',
            attributes_Devices: 'Devices',
            attributes_resultado: 'result',
            attributes_Please_input: 'Please input',
            attributes_não_Testar: 'Do Not Test',
            attributes_testar_Attribute: 'Test Attribute in',
            attributes_expresion: 'expression',
            attributes_boolean: 'boolean',
            attributes_number: 'number',
            attributes_string: 'string',
            attributes_typeOut: 'Output Type',
            attributes_Attribute: 'Attributes',
            attributes_description: 'Description',
            attributes_command: 'Command',
            attributes_add_attribute: 'Load Attribute',
            attributes_Not: 'No type available',
            attributes_grupo: 'group'
                    
        },
        group:{
            add:"Add Group",
            search: 'Search Group',
            new: 'Create Group',
            title: 'Manage Groups',
            groups: 'Groups',
            group: 'Group',
            edit: 'Edit',
            name: 'Group',
            father: 'Group Father',
            attributes: 'Attributes'
        },
        server:{
            tarkan: 'Tarkan',
            restart: 'Restart Servidor',
            lazyDeletion: 'Deletion in Safe Mode',
            enableQrDriverId: 'Enable Check-In by QRCode',
            enableAdvancedPerms: 'Advanced Permissions System',
            language: 'Language',
            enableLockUnlock: 'Show lock and unlock buttons',
            deviceLimit: 'Server Device Limit',
            saving: 'Saving your changes...',
            saved: 'Operation performed successfully.',
            server: 'Server',
            preferences: 'Preferences',
            permissions: 'Permissions',
            map: 'Map',
            latitude: 'Latitude',
            longitude: 'Longitude',
            zoom: 'Zoom',
            twelveHourFormat: '12 Hour Clock',
            registration: 'Register',
            bingKey: 'Bing Key',
            coordinateFormat: 'Coordinate Format',
            limitCommands: 'Send Commands',
            deviceReadonly: 'Device Ready Only',
            google_key:'API Google',
            readOnly: 'Read Only'
        },
        driver:{
            driver: 'Driver',
            drivers: 'Drivers',
            name: 'Name',
            saving: 'Saving your driver...',
            saved: 'Driver saved successfully!',
            saveError: 'There was an error saving the driver!',
            uniqueId: 'Driver Unique Id',
            search: 'Search Driver',
            selectError: 'You need to select a driver!',
            confirmDelete: 'You are deleting driver "%name%", Do you really want to continue?',
            deleteSuccess: 'Driver successfully deleted.',
            add: 'New Driver',
            remove: 'Delete Driver',
            litersx100km:'Fuel consumed x 100 KM',
            fuelCost:"Cost Fuel",
            edit: 'Edit Driver',
            newDriver: 'New Driver',
            editDriver: 'Edit Driver',
            qrDriver:{
                enable: 'QR Driver Check-In',
                username: 'Username',
                password: 'Password',
                behaviorRules: 'Permissions',
                unlockDevice: 'Unlock Device',
                autoLogout: 'Auto Logout',
                lockDevice: 'Bloquear o dispositivo sem motorista',
                lockDeviceTimeout: 'Realizar o bloqueio após',
                autoLogoutTimeout: 'Auto Logou Timeout'
            }
        },
        geofence:{
            geofences: 'Geofence',
            search: 'Search Geofence',
            add: 'New Geofence',
            name: 'Title',
            new: 'Create Geofence',
            edit: 'Edit Geofence',
            attributes: 'Attributes',
            type: 'Geofence Type',
            line: 'LINE',
            circle: 'CIRCLE',
            polygon: 'POLYGON',
            editArea: 'Edit Geofence',
            totalArea: 'Total Area',
            deleteConfirm: 'Do you realy want to delete this geofence?',
            errors: {
                FILL_NAME: 'Field name is required.'
            }
        },
        calendar:{
            title: 'Manage Calendars',
            newCalendar: 'New Calendar',
            editCalendar: 'Edit Calendar',
            calendars: 'Calendars',
            calendar: 'Calendar',
            name: 'Title',
            data: 'Schedule',
            add: 'Add Calendar',
            remove: 'Remove Calendar',
            edit: 'Edit Calendar',
            startDate: 'Start Date Time',
            endDate: 'End Date Time',
            repeat: 'Repeat',
            frequency: 'Frequency',
            confirmAdd: 'Add',
            expires: 'End in',
            occurrencies: 'Occurrencies',
            expiration: 'End Date',
            frequencyes: {
                daily: 'Every Day',
                weekly: 'Every Week',
                monthly: 'Monthly',
                yearly: 'Yearly',
            },
            expirations: {
                never: 'Never',
                count: 'After ',
                until: 'In'
            }
        },
        command:{
            savedCommands: 'Saved Commands',
            search: 'Search Commands'
        },
        maintenance: {
            search: 'Search Maintenance',
            title: 'Manage Maintenances',
            newMaintenance: 'Add Maintenance',
            editMaintenance: 'Edit Maintenance',
            maintence: 'Maintenance',
            maintenances: 'Maintenances',
            maintenance: 'Maintenance',
            name: 'Name',
            type: 'Type',
            start: 'Start',
            cicle: 'Cycle',
            add: 'Add Maintenance',
            remove: 'Remove',
            edit: 'Edit',
            types:{
                totalDistance: 'Total distance',
                hours: 'Hours'
            }
        },
        share:{
            add: 'Create share',
            edit: 'Edit Share',
            remove: 'Remove Share'
        }
    };
export default messages;