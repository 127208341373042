export default {
  namespaced: true,
  state: () => ({
    deviceImages: {},
    categoryImages: {},
    // Adiciona um limite máximo para o cache (evita uso excessivo de memória)
    cacheLimit: 100,
    // Contadores para implementar LRU (Least Recently Used)
    accessCounts: {},
    totalAccesses: 0
  }),
  getters: {
    getDeviceImage: (state) => (id) => {
      // Incrementa contador de acesso quando uma imagem é acessada
      if (state.deviceImages[id]) {
        state.accessCounts[`device-${id}`] = state.totalAccesses++;
        return state.deviceImages[id];
      }
      return null;
    },
    getCategoryImage: (state) => (category) => {
      // Incrementa contador de acesso quando uma imagem é acessada
      if (state.categoryImages[category]) {
        state.accessCounts[`category-${category}`] = state.totalAccesses++;
        return state.categoryImages[category];
      }
      return null;
    }
  },
  mutations: {
    setDeviceImage(state, { id, imageData }) {
      // Implementa estratégia LRU para limitar o tamanho do cache
      const totalCacheSize = Object.keys(state.deviceImages).length + 
                            Object.keys(state.categoryImages).length;
      
      if (totalCacheSize >= state.cacheLimit) {
        // Encontra a entrada menos usada recentemente
        let leastUsedKey = null;
        let leastUsedCount = Infinity;
        
        for (const key in state.accessCounts) {
          if (state.accessCounts[key] < leastUsedCount) {
            leastUsedKey = key;
            leastUsedCount = state.accessCounts[key];
          }
        }
        
        // Remove a entrada menos usada
        if (leastUsedKey) {
          const [type, itemId] = leastUsedKey.split('-');
          if (type === 'device') {
            delete state.deviceImages[itemId];
          } else if (type === 'category') {
            delete state.categoryImages[itemId];
          }
          delete state.accessCounts[leastUsedKey];
        }
      }
      
      // Adiciona a nova imagem ao cache
      state.deviceImages[id] = imageData;
      // Registra o acesso mais recente
      state.accessCounts[`device-${id}`] = state.totalAccesses++;
    },
    setCategoryImage(state, { category, imageData }) {
      // Implementa estratégia LRU para limitar o tamanho do cache
      const totalCacheSize = Object.keys(state.deviceImages).length + 
                            Object.keys(state.categoryImages).length;
      
      if (totalCacheSize >= state.cacheLimit) {
        // Encontra a entrada menos usada recentemente
        let leastUsedKey = null;
        let leastUsedCount = Infinity;
        
        for (const key in state.accessCounts) {
          if (state.accessCounts[key] < leastUsedCount) {
            leastUsedKey = key;
            leastUsedCount = state.accessCounts[key];
          }
        }
        
        // Remove a entrada menos usada
        if (leastUsedKey) {
          const [type, itemId] = leastUsedKey.split('-');
          if (type === 'device') {
            delete state.deviceImages[itemId];
          } else if (type === 'category') {
            delete state.categoryImages[itemId];
          }
          delete state.accessCounts[leastUsedKey];
        }
      }
      
      // Adiciona a nova imagem ao cache
      state.categoryImages[category] = imageData;
      // Registra o acesso mais recente
      state.accessCounts[`category-${category}`] = state.totalAccesses++;
    }
  },
  actions: {
    cacheDeviceImage({ commit }, { id, imageData }) {
      commit('setDeviceImage', { id, imageData });
    },
    cacheCategoryImage({ commit }, { category, imageData }) {
      commit('setCategoryImage', { category, imageData });
    },
    // Adiciona método para pré-carregar imagens comuns (pode ser chamado no início do app)
    async preloadCommonImages({ dispatch, state }) {
      // Lista de IDs de dispositivos e categorias comuns
      const commonCategories = ['default', 'car', 'truck', 'motorcycle', 'bus'];
      
      // Função auxiliar para carregar uma imagem
      const loadImage = (src) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            resolve(canvas.toDataURL("image/png"));
          };
          img.onerror = () => resolve(null);
          img.src = src;
        });
      };
      
      // Pré-carrega categorias comuns
      for (const category of commonCategories) {
        if (!state.categoryImages[category]) {
          const imageData = await loadImage(`/tarkan/assets/images/categories/${category}.png`);
          if (imageData) {
            dispatch('cacheCategoryImage', { category, imageData });
          }
        }
      }
    }
  }
}