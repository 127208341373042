export default {
    0: (()=>{
        return window.location.host.toUpperCase();
    })(),
    1: (()=>{
        return window.location.host
    })(),
    2: (()=>{
        return window.location.host
    })(),
    3: (()=>{
        return window.location.host
    })()
}