const messages = {
  version: '1.14.0',
  HTTP_401_UNAUTHORIZED: 'Combinación de usuario y contraseña inválida.',
  ACCOUNT_IS_SUSPENDED: 'Tu cuenta está suspendida por falta de pago. Por favor, contacta al soporte o regulariza tus deudas.',
  ACCOUNT_IS_SUSPENDED_TRUST: 'Tu cuenta está suspendida por falta de pago, pero no te preocupes, puedes realizar el desbloqueo de confianza antes de regularizar tus deudas.',
  ACCOUNT_IS_DISABLED: 'Esta cuenta ha sido deshabilitada, contacta al administrador.',
  errors: {
      USER_DEVICE_LIMIT_REACHED: "Límite de dispositivos del usuario alcanzado",
      "ACCOUNT_IS_READONLY": "La cuenta está en modo solo lectura",
      "ACCOUNT_IS_DEVICE_READONLY": "La cuenta está en modo solo lectura para dispositivos",
      "WRITE_ACCESS_DENIE": "Error, no es posible escribir en el dispositivo",
      action_cancel: 'Acción Cancelada'
  },
  NEW_VERSION_AVAILABLE: 'NUEVA VERSIÓN DISPONIBLE 1.14.0',
  ALL_TASK_READY: "Todas las tareas completadas con éxito.",
  "FILL_ALL_FIELDS": "Por favor, completa todos los campos.",
  "INVALID_LOGIN_DATA": "Lo sentimos, parece que ingresaste datos incorrectos.",
  "NO_DATA_TEXT": "No hay datos disponibles",
  "NO_MATCH_TEXT": "No Encontrado",
  output_type: 'Tipo de Salida',
  output_type_text: 'Texto',
  output_type_Numeric: 'Numérico',
  output_type_Logical: 'Lógico',
  expression: 'Expresión',
  Please_input: 'Por favor, ingresa',
  Atribut: 'Atributo',
  descriptionCommand: 'Descripción',
  Command_2: 'Comando',
  do_not_test: 'No probar',
  Test_Attribute_on: 'Probar atributo activado',
  Outcome: 'Resultado',
  active_text: 'Aplicar este atributo a mis favoritos',
  devices: 'Dispositivos',
  Attribute_Registry: 'Atributo computado registrado con éxito.',
  Binding_attribute_device: 'Atributo vinculado al dispositivo',
  registry_attribute: 'Registrando tu atributo...',
  computed_attribute_deleted: 'Atributo computado eliminado como evento.',
  computed_attribute_deleted_confirmation: '¿Realmente deseas eliminar este atributo computado? ¿Estás seguro?',
  "LOADING": "Cargando...",
  "route": {
      "start": "Inicio de la Ruta",
      "end": "Fin de la Ruta",
      "empty": "Ninguna ruta para el período seleccionado."
  },
  "sorting": {
      "name-asc": "Nombre Ascendente",
      "name-desc": "Nombre Descendente",
      "lastUpdate-asc": "Última Actualización Ascendente",
      "lastUpdate-desc": "Última Actualización Descendente",
      "state-motion": "Movimiento",
      "state-anchor": "Anclaje",
      "state-locked": "Bloqueado",
      "state-ignition": "Encendido",
      "state-driver": "Conductor",
      "state-alert": "Alerta"
  },
  "customize": "Personalizar",
  "preferences": "Preferencias",
  "search": "Buscar",
  "page": "Página",
  "now": "Ahora",
  "days": "días",
  "hours": "horas",
  "minutes": "minutos",
  "seconds": "segundos",
  "minute": "minuto",
  "lessMinute": "<1 minuto",
  "yes": "Sí",
  "no": "No",
  "new": "Nuevo",
  "all": "Todos",
  "online": "En línea",
  "offline": "Desconectado",
  "unknown": "Desconocido",
  "rememberme": "Recordarme",
  "enabled": "Habilitado",
  "disabled": "Deshabilitado",
  "fixed": "Fijado",
  "percent": "Porcentaje",
  "always": "Siempre",
  "automatic": "Automático",
  "set": "Definido",
  "unlimited": "Ilimitado",
  "ok": "OK",
  "save": "Guardar",
  "cancel": "Cancelar",
  "delete": "Eliminar",
  "danger": "¡Peligro!",
  "info": "Espera...",
  "success": "¡Éxito!",
  "sure": "¿Estás seguro?",
  "ops": "Ups",
  "userCancel": "Operación cancelada por el usuario.",
  "today": "Hoy",
  "yesterday": "Ayer",
  "thisweek": "Últimos 7 días",
  "thismonth": "Últimos 30 días",
  "threemonths": "Últimos 90 días",
  "startDate": "Fecha de Inicio",
  "endDate": "Fecha de Fin",
  "to": "hasta",
  "weekDays": {
      "SU": "Dom",
      "MO": "Lun",
      "TU": "Mar",
      "WE": "Mié",
      "TH": "Jue",
      "FR": "Vie",
      "ST": "Sáb"
  },
  units: {
      currency: (ctx) => {
          return "R$ " + parseFloat(ctx.named('value')).toFixed(2);
      },
      speedUnit: (ctx) => {
          return Math.round(ctx.named('speed') * 1.852) + ' km/h';
      },
      speedUnitReverse: (ctx) => {
          return Math.round(ctx.named('speed') / 1.852) + ' km/h';
      },
      distanceUnit: (ctx) => {
          return ((ctx.named('distance') / 1000) * 0.621371).toFixed(2) + ' mi';
      },
      miles: (ctx) => {
          return ((ctx.named('distance') / 1000) * 0.621371).toFixed(2) + ' mi';
      },
      km: (ctx) => {
          return (ctx.named('distance') / 1000).toFixed(2) + ' km';
      },
      mph: (ctx) => {
          return Math.round(ctx.named('speed') * 1.15078) + ' mph';
      },
      mphReverse: (ctx) => {
          return Math.round(ctx.named('speed') / 1.15078) + ' mph';
      },
      kmh: (ctx) => {
          return Math.round(ctx.named('speed') * 1.852) + ' km/h';
      },
      kmhReverse: (ctx) => {
          return Math.round(ctx.named('speed') / 1.852) + ' km/h';
      },
      kn: (ctx) => {
          return ctx.named('speed') + ' kn';
      },
      knReverse: (ctx) => {
          return ctx.named('speed') + ' kn';
      },
      ignition: (ctx) => {
          return (ctx.named('value')) ? 'Sí' : 'No';
      },
      blocked: (ctx) => {
          return (ctx.named('value')) ? 'Sí' : 'No';
      },
      motion: (ctx) => {
          return (ctx.named('value')) ? 'Sí' : 'No';
      },
      valid: (ctx) => {
          return (ctx.named('value')) ? 'Sí' : 'No';
      },
      distance: (ctx) => {
          return (ctx.named('value') / 1000).toFixed(2) + ' km';
      },
      totalDistance: (ctx) => {
          return (ctx.named('value') / 1000).toFixed(2) + ' km';
      },
      speed: (ctx) => {
          return Math.round(ctx.named('value') * 1.852) + ' km/h';
      },
      serverTime: (ctx) => {
          return new Date(ctx.named('value')).toLocaleString();
      },
      deviceTime: (ctx) => {
          return new Date(ctx.named('value')).toLocaleString();
      },
      fixTime: (ctx) => {
          return new Date(ctx.named('value')).toLocaleString();
      }
  },
  menu: {
      autoLink: 'Agregar Dispositivo',
      devices: 'Dispositivos',
      history: 'Historial',
      geofence: 'Geocerca',
      commands: 'Comandos',
      groups: 'Grupos',
      notifications: 'Notificaciones',
      reports: 'Informes',
      reportsHistory: 'Rutas',
      reportsResume: 'Resumen',
      reportsEvents: 'Eventos',
      reportsStops: 'Paradas',
      reportsTravels: 'Viajes',
      computedAttributes: 'Atributos Computados',
      soundNotification :'Escuchar Notificaciones',
      noSoundNotification :'No Escuchar Notificaciones'
  },
  usermenu: {
      account: 'Mi Cuenta',
      users: 'Usuarios',
      theme: 'Apariencia',
      logs: 'Registros Avanzados',
      integrations: 'Integraciones',
      invoices: 'Mis Facturas',
      billing: 'Gestión de Cobranza',
      reseller: 'Revendedor',
      computedAttributes: 'Atributos Computados',
      server: 'Servidor',
      notifications: 'Notificaciones',
      drivers: 'Conductores',
      calendars: 'Calendarios',
      maintenance: 'Mantenimiento',
      logout: 'Salir'
  },
  "theme": {
      "edit": "Editar Tema",
      "general": "General",
      "login": "Pantalla de Inicio de Sesión",
      "logos": "Logos",
      "icon": "Ícono",
      "generalColors": "Colores Generales",
      "textColors": "Colores de Texto",
      "otherColors": "Otros Colores",
      "appName": "Nombre del Servicio",
      "whatsapp": "WhatsApp",
      "internalLogo": "Logotipo Interno",
      "logoAsImage": "Mostrar como imagen",
      "logoAsText": "Mostrar como texto",
      "filterColor": "Color del Filtro",
      "backgroundImage": "Imagen de Fondo",
      "logoOnLogin": "En la Pantalla de Inicio de Sesión",
      "logoOnHead": "Logotipo Interno",
      "logoSize": "Tamaño del Logotipo",
      "backgroundColor": "Color de Fondo",
      "generalWhite": "Blanco General",
      "primary": "Primario",
      "textColor": "Color del Texto",
      "generalBlack": "Negro General",
      "variant1": "Variante Primaria 1",
      "variant2": "Variante Primaria 2",
      "variant3": "Variante Primaria 3",
      "variant4": "Variante Primaria 4",
      "variant5": "Variante Primaria 5",
      "variant6": "Variante Primaria 6",
      "variant7": "Variante Primaria 7",
      "variant8": "Variante Primaria 8",
      "variant9": "Variante Primaria 9",
      "textPrimary": "Texto Primario",
      "textRegular": "Texto Regular",
      "textSecondary": "Texto Secundario",
      "textWhite": "Texto Blanco",
      "success": "Éxito",
      "successVariant1": "Variante de Éxito 1",
      "successVariant2": "Variante de Éxito 2",
      "alert": "Alerta",
      "alertVariant1": "Variante de Alerta 1",
      "alertVariant2": "Variante de Alerta 2",
      "danger": "Peligro",
      "dangerVariant1": "Variante de Peligro 1",
      "dangerVariant2": "Variante de Peligro 2",
      "info": "Información",
      "infoVariant1": "Variante de Información 1",
      "infoVariant2": "Variante de Información 2"
  },
  report: {
      selectType: 'Selecciona el tipo de informe deseado',
      history: 'Rutas',
      resume: 'Resumen',
      stops: 'Paradas',
      travels: 'Viajes',
      events: 'Eventos',
      load: 'Cargar',
      export: 'Exportar',
      showMarkers: 'Mostrar Marcadores'
  },
  alarms: {
      none: 'Ninguna Alerta',
      alarm: 'Alarma',
      alarms: 'Alarmas',
      general: 'General',
      sos: 'SOS',
      vibration: 'Vibración',
      lowspeed: 'Velocidad Baja',
      overspeed: 'Velocidad Alta',
      movement: 'Movimiento',
      fallDown: 'Caída',
      lowPower: 'Baja Tensión de la Batería',
      lowBattery: 'Batería Baja',
      fault: 'Fallo',
      powerOff: 'Encendido Apagado',
      powerOn: 'Encendido Encendido',
      door: 'Puerta',
      unlock: 'Desbloqueado',
      lock: 'Bloqueado',
      geofence: 'Geocerca',
      geofenceEnter: 'Entrada en la Geocerca',
      geofenceExit: 'Salida de la Geocerca',
      gpsAntennaCut: 'Corte de GPS',
      accident: 'Accidente',
      tow: 'Remolque',
      idle: 'Inactivo',
      highRpm: 'Alta Rotación',
      hardAcceleration: 'Aceleración Brusca',
      hardBraking: 'Frenado Brusco',
      hardCornering: 'Curva Brusca',
      laneChange: 'Cambio de Carril',
      fatigueDriving: 'Fatiga',
      powerCut: 'Corte de Energía',
      powerRestored: 'Energía Restaurada',
      jamming: 'Interferencia',
      temperature: 'Temperatura',
      parking: 'Estacionamiento',
      shock: 'Choque',
      bonnet: 'Capó',
      footBrake: 'Freno de Mano',
      fuelLeak: 'Fuga de Combustible',
      tampering: 'Manipulación',
      removing: 'Remoción'
  },
  graph: {
      graphicType: 'Tipo de Gráfico'
  },
  integrations: {
      title: 'Integraciones',
      enable: 'Habilitar',
      configure: 'Configurar',
      asaas: {
          title: 'Asaas',
          inputs: {
              email: 'Correo Electrónico',
              token: 'Token',
              sandbox: 'Habilitar Entorno de Pruebas'
          }
      },
      email: {
          title: 'Correo Electrónico',
          inputs: {
              username: 'Nombre de Usuario',
              password: 'Contraseña',
              mailfrom: 'Correo Electrónico del Remitente',
              mailfrom_name: 'Nombre del Remitente',
              smtp_host: 'Host SMTP',
              smtp_port: 'Puerto SMTP'
          }
      },
      invoice: {
          title: 'Factura Tarkan',
          inputs: {
              invoices_title: 'Integración de Factura',
              password: 'Contraseña',
              mailfrom: 'Correo Electrónico del Remitente',
              mailfrom_name: 'Nombre del Remitente',
              smtp_host: 'Host SMTP',
              smtp_port: 'Puerto SMTP'
          }
      }
  },
  "notification": {
      command: 'Comandos',
      listen: 'Prueba de notificaciones',
      "autoAdd": "Agregar automáticamente a nuevos usuarios",
      "fillRequiredFields": "Los campos Tipo, Canales, Alarmas deben ser completados",
      "test": "Notificación de prueba",
      "testSent": "La notificación de prueba se ha enviado correctamente",
      "search": "Buscar Notificación",
      "deleted": "Notificación eliminada con éxito.",
      "selectError": "Debe seleccionar un usuario para editar.",
      "saveError": "No se pudo guardar la notificación.",
      "confirmDelete": "Estás eliminando una notificación de \"%type%\", ¿deseas continuar?",
      "saving": "Guardando tu notificación",
      "saved": "Tu notificación ha sido guardada con éxito.",
      "linkToDevice": "Asignando al dispositivo \"%name%\"",
      "linkToGroup": "Asignando al grupo \"%name%\"",
      "linkToUser": "Asignando al usuario \"%name%\"",
      "title": "Gestionar Notificaciones",
      "notification": "Notificación",
      "notifications": "Notificaciones",
      "newNotification": "Registrar Notificación",
      "editNotification": "Editar Notificación",
      "add": "Agregar",
      "remove": "Eliminar",
      "edit": "Editar",
      "type": "Tipo",
      "all": "Todos los Dispositivos",
      "alarms": "Alarmas",
      "channel": "Canales",
      "customize": "Personalizar",
      "position": "Posición",
      "color": "Color",
      "sound": "Sonido de Notificación",
      "soundURL": "URL del SONIDO (mp3, wav, ogg)",
      "pin": "Fijar",
      "sounds": {
          "mute": "Silencio",
          "custom": "URL",
          "audio1": "1 - Apple",
          "audio2": "2 - Huawei",
          "audio3": "3 - Huawei Tone",
          "audio4": "4 - Apple Tone",
          "audio5": "5 - Huawei Cave",
          "audio6": "6 - Apple Pay",
          "audio7": "7 - Brillante",
          "audio8": "8 - Paso de Peatones",
          "audio9": "9 - Paso de Peatones Japón",
          "audio10": "10 - Configurar",
          "audio11": "11 - Ding de iPhone",
          "audio12": "12 - Netflix",
          "audio13": "13 - Moneda",
          "audio14": "14 - Interruptor",
          "audio15": "15 - Anillos Sónicos",
          "audio16": "16 - Alarma de Policía",
          "audio17": "17 - Tonalidad de Policía",
          "audio18": "18 - Alerta de Policía",
          "audio19": "19 - Notificación de Policía",
          "audio20": "20 - Policía",
          "audio21": "21 - Claro",
          "audio22": "22 - Silbato",
          "audio23": "23 - S8/NOTE",
          "audio24": "24 - Alarma"
      },
      "colors": {
          "soft-red": "Rojo Claro",
          "red": "Rojo",
          "soft-green": "Verde Claro",
          "green": "Verde",
          "soft-yellow": "Amarillo Claro",
          "yellow": "Amarillo",
          "soft-info": "Azul Claro",
          "info": "Azul"
      },
      "positions": {
          "top-right": "Superior Derecho",
          "top-left": "Superior Izquierdo",
          "bottom-right": "Inferior Derecho",
          "bottom-left": "Inferior Izquierdo"
      },
      "types": {
          "alarm": "Alarma",
          "deviceOnline": "Dispositivo en Línea",
          "deviceOffline": "Dispositivo Offline",
          "textMessage": "Mensaje de Texto",
          "deviceUnknown": "Dispositivo Desconocido",
          "geofenceExit": "Salida de la Geocerca",
          "geofenceEnter": "Entrada en la Geocerca",
          "ignitionOff": "Ignición Apagada",
          "ignitionOn": "Ignición Encendida",
          "deviceMoving": "Dispositivo en Movimiento",
          "deviceStopped": "Dispositivo Detenido",
          "deviceOverspeed": "Dispositivo en Exceso de Velocidad",
          "commandResult": "Resultado del Comando",
          "driverChanged": "Cambio de Conductor",
          "deviceFuelDrop": "Caída de Combustible",
          "maintenance": "Mantenimiento",
          "deviceInactive": "Dispositivo Inactivo"
      },
      "channels": {
          "web": "Web",
          "firebase": "Celular",
          "telegram": "Telegram",
          "sms": "SMS",
          "mail": "Correo Electrónico"
      },
      "messages": {
          "test": "Notificación de Prueba",
          "alarm": "Alarma",
          "deviceOnline": "Dispositivo en Línea",
          "deviceOffline": "Dispositivo Offline",
          "textMessage": "Mensaje de Texto",
          "deviceUnknown": "Dispositivo Desconocido",
          "geofenceExitName": "Salida de la Geocerca: %name%",
          "geofenceExit": "Salió de una Geocerca.",
          "geofenceEnterName": "Entrada en la Geocerca: %name%",
          "geofenceEnter": "Entró en una Geocerca.",
          "ignitionOff": "Ignición Apagada",
          "ignitionOn": "Ignición Encendida",
          "deviceMoving": "Dispositivo en Movimiento",
          "deviceStopped": "Dispositivo Detenido",
          "deviceOverspeed": "Dispositivo en Exceso de Velocidad",
          "commandResult": "Resultado del Comando: %result%",
          "driverChanged": "Cambio de Conductor",
          "deviceFuelDrop": "Caída de Combustible",
          "maintenance": "Mantenimiento",
          "deviceInactive": "Dispositivo Inactivo"
      }
  },
  "login": {
      "username": "Nombre de Usuario",
      "password": "Contraseña",
      "your_email": "Tu Correo Electrónico",
      "your_password": "Tu Contraseña",
      "signin": "Acceder",
      "suspended": "Cuenta Suspendida",
      "lost_password": "Recuperar Contraseña",
      "lost_send": "Solicitud de contraseña enviada. En breve recibirás un correo de confirmación.",
      "password_changed": "Tu contraseña ha sido modificada con éxito. Ahora puedes iniciar sesión con tu nueva contraseña.",
      "register": "Registrarse",
      "charge": "Accediendo al compartir, por favor espera..."
  },
  "actions": {
      "custom": "Comando Personalizado",
      "engineStop": "Bloquear Motor",
      "engineResume": "Desbloquear Motor",
      "anchorEnable": "Habilitar Anclaje",
      "anchorDisable": "Deshabilitar Anclaje",
      "deviceIdentification": "Identificación del Dispositivo",
      "positionSingle": "Posición Única",
      "alarmArm": "Activar Alarma",
      "alarmDisarm": "Desactivar Alarma",
      "outputControl": "Control de Salida",
      "rebootDevice": "Reiniciar Dispositivo",
      "offline": "Desconectado"
  },
  "invoice": {
      "id": "ID",
      "dueDate": "Fecha de Vencimiento",
      "value": "Valor",
      "status": "Estado",
      "paidDate": "Fecha de Pago",
      "count": "Total de Cobranzas",
      "pending": "Pendiente",
      "paid": "Pagado",
      "view": "Ver Facturas",
      "add": "Agregar Factura",
      "delete": "Cancelar Factura",
      "unlock": "Desbloquear",
      "trustUnlock": "Desbloqueo de Confianza",
      "lock": "Suspender Acceso",
      "alreadyTrust": "Ya has utilizado el desbloqueo de confianza.",
      "confirmDelete": "¿Realmente deseas cancelar esta factura?",
      "confirmDeleteSuccess": "Factura cancelada con éxito!",
      "confirmUnlock": "¿Realmente deseas realizar el desbloqueo de confianza para el usuario %name%?",
      "confirmUnlockSuccess": "Usuario desbloqueado con éxito.",
      "confirmLock": "¿Realmente deseas suspender la cuenta del usuario %name%?",
      "confirmLockSuccess": "Usuario suspendido con éxito.",
      "confirmCash": "¿Realmente deseas marcar la factura como recibida?",
      "confirmCashSuccess": "Operación realizada con éxito.",
      "receivedCash": "Marcar como Recibido en Efectivo",
      "statuses": {
          "PENDING": "Pendiente de Pago",
          "RECEIVED_IN_CASH": "Recibido en Efectivo",
          "RECEIVED": "Recibido",
          "CONFIRMED": "Confirmado",
          "OVERDUE": "Vencido",
          "REFUND_REQUESTED": "Reembolso Solicitado",
          "REFUND_IN_PROGRESS": "Reembolso en Proceso",
          "CHARGEBACK_REQUESTED": "Reversión Solicitada",
          "CHARGEBACK_DISPUTE": "En Disputa",
          "AWAITING_CHARGEBACK_REVERSAL": "Disputa Ganada",
          "DUNNING_REQUESTED": "En Proceso de Cobranza",
          "DUNNING_RECEIVED": "Recuperada",
          "AWAITING_RISK_ANALYSIS": "Esperando Análisis de Riesgo"
      }
  },
  "user": {
      "billing": "Facturación",
      "billingEnable": "Habilitar Facturación",
      "billingCpfCnpj": "CPF/CNPJ",
      "billingTrustUnlock": "Permitir Desbloqueo de Confianza",
      "billingTrustUnlockDays": "¿Cuántos días de confianza?",
      "billingSuspension": "Habilitar Suspensión Automática",
      "billingSuspensionDays": "¿Cuántos días después del vencimiento para suspender?",
      "billingDescription": "Descripción de la Factura",
      "billingDate": "Día del Primer Vencimiento",
      "billingPrice": "Valor de la Factura",
      "billingFine": "Monto de la Multa",
      "billingFineMode": "Modo de la Multa",
      "billingInterest": "Interés",
      "billingDisccount": "Descuento",
      "billingDisccountMode": "Modo de Descuento",
      "billingDisccountDays": "¿Cuántos días antes del vencimiento para el descuento?",
      "accountInfo": "Detalles de la Cuenta",
      "preferences": "Preferencias del Usuario",
      "permissions": "Permisos del Usuario",
      "new": "Nuevo Usuario",
      "device": "Dispositivo",
      "expirationTime": "Fecha de Expiración",
      "shareLink": "Enlace de Compartición",
      "logs": "Registros",
      "users": "Usuarios",
      "user": "Usuario",
      "search": "Buscar Usuario",
      "add": "Registrar Usuario",
      "edit": "Editar Usuario",
      "remove": "Eliminar Usuario",
      "title": "Gestionar Usuarios",
      "id": "ID",
      "name": "Nombre",
      "phone": "Teléfono",
      "email": "Correo Electrónico",
      "password": "Contraseña",
      "passwordConfirm": "Confirmar Contraseña",
      "admin": "Administrador",
      "disabled": "Bloqueado",
      "language": "Idioma",
      "map": "Mapa",
      "latitude": "Latitud",
      "longitude": "Longitud",
      "twelveHourFormat": "Formato de 12 Horas",
      "zoom": "Zoom",
      "coordinateFormat": "Formato de Coordenadas",
      "userStatus": "Estado del Usuario",
      "readOnly": "Solo Lectura",
      "editDevices": "Editar Dispositivos",
      "limitCommands": "Limitar Comandos",
      "expirationDate": "Fecha de Validez",
      "deviceLimit": "Límite de Dispositivos",
      "userLimit": "Límite de Usuarios",
      "token": "Token de Acceso",
      "selectUser": "Debes seleccionar un usuario",
      "cannotDeleteSelf": "¡No puedes eliminarte a ti mismo!",
      "cannotDeleteAdmin": "¡No puedes eliminar a un administrador superior a ti!",
      "confirmDelete": "Estás eliminando al usuario {name}, ¿deseas continuar?",
      "dangerTitle": "¡Peligro!",
      "delete": "Eliminar",
      "cancel": "Cancelar",
      "success": "Usuario eliminado con éxito.",
      "successMessage": "Usuario eliminado con éxito.",
      "successTitle": "Eliminar Usuario.",
      "error": {
          "cannotDeleteSelf": "¡No puedes eliminarte a ti mismo!",
          "error": "Error",
          "save": "¡Error!",
          "selectUser": "Selecciona un usuario para editar.",
          "checkForm": "Verifica los datos del formulario.",
          "superior": "No puedes editar a un administrador superior a ti.",
          "select": "Selecciona un usuario para editar.",
          "USER_NOT_AUTHED": "Actualiza tu página",
          "WRITE_ACCESS_DENIED": "ACCESO DENEGADO"
      },
      "form": {
          "userNameEmpty": "Ingresa un nombre y apellido",
          "userNameLength": "Mínimo de 3 caracteres",
          "emailEmpty": "Ingresa un correo electrónico válido",
          "emailLength": "Mínimo de 4 caracteres requeridos",
          "passwordEmpty": "Ingresa una contraseña",
          "passwordLength": "Mínimo de 4 caracteres requeridos"
      },
      "advanced": {
          "reseller": "Revendedor",
          "subReseller": "Puede crear nuevos revendedores",
          "domainReseller": "Dominio del Revendedor",
          "limitReseller": "Límite de Revendedores",
          "lock": "Bloquear",
          "unlock": "Desbloquear",
          "command": "Enviar Comandos",
          "create": "Crear",
          "edit": "Editar",
          "delete": "Eliminar",
          "self": "Puede editarse",
          "devices": "Dispositivos",
          "reports": "Informes",
          "geofences": "Geocercas",
          "commands": "Comandos",
          "groups": "Grupos",
          "notifications": "Notificaciones",
          "drivers": "Conductores",
          "calendar": "Calendarios",
          "anchor": "Anclaje",
          "share": "Compartición",
          "users": "Usuarios",
          "attributes": "Atributos Computados",
          "calendars": "Calendarios",
          "maintenance": "Mantenimientos"
      }
  },
  "map": {
      "showGroups": "Mostrar Grupos",
      "showGeofences": "Mostrar Geocercas",
      "showNames": "Mostrar Nombres",
      "showPlates": "Mostrar Placas",
      "showPrecision": "Mostrar Precisión",
      "showStatus": "Mostrar Estado",
      "devices": {
          "default": "Predeterminado",
          "arrow": "Flecha",
          "person": "Persona",
          "animal": "Animal",
          "bicycle": "Bicicleta",
          "motorcycle": "Motocicleta",
          "scooter": "Scooter",
          "car": "Auto",
          "pickup": "Pick-Up",
          "van": "Furgoneta",
          "truck": "Camión",
          "truck1": "Camión 1",
          "truck2": "Camión 2",
          "tractor": "Tractor",
          "boat": "Barco",
          "ship": "Barco Grande",
          "bus": "Autobús",
          "train": "Tren",
          "trolleybus": "Trolebús",
          "tram": "Tranvía",
          "crane": "Grúa",
          "plane": "Avión",
          "helicopter": "Helicóptero",
          "offroad": "Todo Terreno"
      }
  },

"device": {
      "manageQrCode": "Código QR del Dispositivo",
      "noDriver": "Sin Conductor",
      "anchorDisabled": "Anclaje Desactivado",
      "anchorEnabled": "Anclaje Habilitado",
      "odometer": "Odómetro (en metros)",
      "category": "Categoría",
      "motion": "Movimiento",
      "stopped": "Detenido",
      "lastStoppedTime": "Detenido desde",
      "loading": "Cargando datos del dispositivo...",
      "driver": "Conductor",
      "isQrDriverId": "Conductor identificado por Código QR",
      "showQrCode": "Código QR del Conductor",
      "shareLink": "Compartir Enlace Temporal",
      "shareMaps": "Compartir en Google Maps",
      "shareStreet": "Compartir en Street View",
      "openMaps": "Google Maps",
      "openStreet": "Street View",
      "saveError": "No se pudo guardar tu dispositivo.",
      "confirmDelete": "Estás eliminando el dispositivo \"%name%\", ¿deseas continuar?",
      "saving": "Guardando tu dispositivo",
      "saved": "Operación realizada con éxito.",
      "attributions": "Atribuciones",
      "title": "Gestionar Dispositivos",
      "devices": "Dispositivos",
      "device": "Dispositivo",
      "logs": "Registros",
      "share": "Compartir",
      "openExternal": "Abrir en",
      "routes": "Rutas",
      "graphic": "Gráfico",
      "startOdometer": "Inicio del Odómetro",
      "endOdometer": "Fin del Odómetro",
      "averageSpeed": "Velocidad Promedio",
      "maxSpeed": "Velocidad Máxima",
      "engineHours": "Horas de Motor",
      "fuel": "Combustible",
      "spentFuel": "Costo de Combustible",
      "currency": "Moneda",
      "fuelPrice": "Precio del Combustible",
      "litersx100km": "Unidades de Combustible por 100 km",
      "duration": "Duración",
      "battery": "Batería",
      "batteryLevel": "Nivel de la Batería",
      "ignitionOn": "Encendido",
      "ignitionOff": "Apagado",
      "blocked": "Bloqueado",
      "unblocked": "Desbloqueado",
      "rssi": "Intensidad de la Señal",
      "network": "Red",
      "sat": "Satélite",
      "sattelites": "Satélites",
      "power": "Tensión de Alimentación",
      "alarm": "Alarma",
      "alarms": "Alarmas",
      "unknown": "Desconocido",
      "disabled": "Desactivado",
      "new": "Nuevo",
      "moving": "Moviendo",
      "stoped": "Detenido",
      "noPosition": "Sin Posición",
      "lastPosition": "Ubicación Actual",
      "updatedAt": "Actualizado en",
      "positionSpeed": "Velocidad Actual",
      "distance": "Distancia Total",
      "online": "En Línea",
      "offline": "Desconectado",
      "lastPositionTime": "Hora de la Última Posición",
      "chip": "Chip",
      "follow": "Seguir",
      "unfollow": "Dejar de Seguir",
      "trail": "Mostrar Rastro",
      "untrail": "Ocultar Rastro",
      "zoom": "Acercar",
      "details": "Detalles del Vehículo",
      "lock": "Bloquear",
      "unlock": "Desbloquear",
      "send_command": "Enviar Comando",
      "confirm_lock": "¿Realmente deseas bloquear el vehículo?",
      "questio_bloked": "¿Realmente deseas bloquear el vehículo?",
      "confirm_unlock": "¿Realmente deseas desbloquear el vehículo?",
      "confirm_command": "Estás enviando un comando personalizado al vehículo \"%name%\", ¡algunos comandos pueden afectar el funcionamiento del sistema! ¿Deseas continuar?",
      "command_sent": "Comando enviado con éxito.",
      "edit": "Editar",
      "add": "Registrar",
      "remove": "Eliminar",
      "search": "Buscar",
      "updated": "Actualizado",
      "imei": "Identificador del Dispositivo (IMEI)",
      "name": "Nombre del Dispositivo",
      "status": "Estado",
      "iccid": "ICCID",
      "phone": "Teléfono",
      "operator": "Operadora",
      "brocker": "Brocker",
      "model": "Modelo",
      "plate": "Placa",
      "color1": "Color Primario",
      "color2": "Color Secundario",
      "icon": "Seleccionar Vehículo",
      "hue": "Tonalidad",
      "saturate": "Saturar",
      "brightness": "Brillo",
      "temperature": "Temperatura",
      "historyEvents": "Eventos Recientes",
      "historyPosition": "Historial de Movimiento",
      "form": {
          "nameEmpty": "Por favor, ingresa un nombre para el dispositivo",
          "uniqueIdEmpty": "Por favor, ingresa el identificador único del dispositivo"
      },
      "streetview": "StreetView",
      "questionBloked": "¿Realmente deseas bloquear el vehículo?",
      "success": "Éxito",
      "action_cancel": "Acción cancelada por el usuario",
      "question_blocked": "¿Realmente deseas desbloquear el vehículo?",
      "question_del1": "¿Realmente deseas eliminar el vehículo?",
      "question_del2": "¿Estás de acuerdo?",
      "Info": "Información",
      "device_del": "Dispositivo eliminado con éxito.",
      "marca": "Marca",
      "color": "Color",
      "motor": "Número del Motor",
      "date": "Fecha de Fabricación",
      "observations": "Observaciones",
      "observation": "Observación",
      "vin": "Número VIN",
      "chassis": "Número del Chasis",
      "zeroodometrocombustible": "Reiniciar Odómetro de Combustible",
      "error_device_del": "Ocurrió un error inesperado al eliminar el dispositivo.",
      "rpm": "RPM",
      "error": "Error"
  },
  "instalations": {
      "install": "Instalación",
      "empresa": "Empresa de Instalación",
      "installer": "Instalador",
      "phone": "Teléfono",
      "email": "Correo Electrónico",
      "observation": "Observación",
      "data": "Fecha de Instalación",
      "address": "Dirección"
  },
  "log": {
      "Mess_Carga": "Cargando registros, por favor, espere...",
      "Log": "Registro",
      "Device_acceso": "Dispositivo de Acceso",
      "Mess_log_ok": "Intento de inicio de sesión realizado con éxito.",
      "Mess_log_out": "Intento de inicio de sesión fallido.",
      "Geocerca": "Geocerca",
      "edit": "Editado:",
      "Mess_user_edit": "Intento de eliminar usuario fallido",
      "Link_Share_add": "Enlace de compartición creado",
      "Link_Share_out": "Intento de compartición fallido",
      "Link_Share_edit": "Enlace de compartición editado",
      "Link_Share_edit_err": "Intento de editar compartición fallido",
      "usser_motor_add": "Usuario para acceso de conductor creado.",
      "usser_motor_out": "Intento de crear acceso de conductor fallido.",
      "send_comand": "Comando enviado",
      "send_comand_err": "Envío de comando fallido.",
      "Blocked_motorista": "Bloqueo realizado por la regla de conductor.",
      "Unblocked_motorista": "Desbloqueo realizado por la regla de conductor.",
      "edit_device_err": "Intento de editar el vehículo fallido.",
      "device": "Dispositivo",
      "excluido": "Eliminado",
      "falla": "Fallido",
      "anchor_activ": "Anclaje Activado",
      "ancora_err": "Intento de activar anclaje fallido.",
      "geofence_add": "Geocerca creada",
      "geofence_out": "Intento de crear geocerca fallido.",
      "ancora_aut": "Anclaje Desactivado",
      "ancora_desact_err": "Intento de desactivar anclaje fallido.",
      "deleted": "Eliminado de",
      "geofence_out_err": "Intento de eliminar geocerca fallido.",
      "geofence_out_ok": "Geocerca Eliminada",
      "idem": "Sin cambio."
  },
  "commands": {
      "command": "Comando",
      "search": "Buscar Comando",
      "title": "Título",
      "type": "Tipo",
      "new": "Nuevo Comando",
      "edit": "Editar Comando",
      "savedCommands": "Comandos Guardados",
      "confirmDelete": "¿Realmente deseas borrar este comando?",
      "changeNative": "Cambiar la acción del comando nativo",
      "changeAction": "Cambiar Acción",
      "success": "Comando guardado con éxito",
      "successUpdate": "Comando actualizado con éxito",
      "error": "Error al guardar el comando",
      "errorUpdate": "Error al actualizar el comando",
      "deleted": "Comando eliminado con éxito"
  },
  "attribute": {
        "title": "Gestionar Atributos",
        "attribute": "Atributo",
        "value": "Valor",
        "add": "Agregar Atributo",
        "attributes": "Atributos",
        "computedAttributes": "Atributos Computados",
        "result": "Resultado",
        "search": "Buscar Atributo",
        "id": "ID",
        "batteryLevel": "Nivel de Batería",
        "battery": "Batería",
        "power": "Tensión de Alimentación",
        "rpm": "RPM",
        "alarms": "Alarmas",
        "totalDistance": "Distancia Total",
        "deviceId": "ID del Dispositivo",
        "protocol": "Protocolo",
        "serverTime": "Hora del Servidor",
        "deviceTime": "Hora del Dispositivo",
        "rssi": "Intensidad de la Señal",
        "raw": "Crudo",
        "adc1": "Tensión de la Batería del Vehículo",
        "out": "Salida",
        "out1": "Salida1",
        "out2": "Salida2",
        "out3": "Salida3",
        "out4": "Salida4",
        "in": "Entrada",
        "in1": "Entrada1",
        "in2": "Entrada2",
        "in3": "Entrada3",
        "in4": "Entrada4",
        "fixTime": "Hora del GPS",
        "valid": "Válido",
        "spentFuel": "Costo de Combustible",
        "fuel": "Combustible",
        "outdated": "Desactualizado",
        "type": "Tipo",
        "versionFw": "Versión FW",
        "status": "Estado",
        "index": "Índice",
        "priority": "Prioridad",
        "io200": "io200",
        "io1": "io1",
        "io2": "io2",
        "io69": "io69",
        "pdop": "pdop",
        "hdop": "hdop",
        "io24": "io24",
        "io9": "io9",
        "io6": "io6",
        "operator": "Operadora",
        "driverUniqueId": "ID del Conductor",
        "ip": "IP",
        "latitude": "Latitud",
        "longitude": "Longitud",
        "altitude": "Altitud",
        "address": "Dirección",
        "accuracy": "Precisión",
        "speed": "Velocidad",
        "odometer": "Odómetro",
        "hours": "Horas",
        "distance": "Distancia",
        "course": "Ángulo",
        "motion": "Movimiento",
        "alarm": "Alarma",
        "event": "Evento",
        "sat": "Satélites",
        "blocked": "Bloqueado",
        "ignition": "Encendido",
        "network": "Conexión",
        "speedLimit": "Límite de Velocidad",
        "lockOnExit": "Bloquear al Salir de la Geocerca",
        "lockOnEnter": "Bloquear al Entrar en la Geocerca",
        "telegramChatId": "ID de Telegram",
        "currency": "Moneda",
        "fuelPrice": "Precio del Combustible",
        "litersx100km": "Combustible x 100 Km",
        "fuelCost": "Costo del Combustible",
        "notificationTokens": "Tokens de Notificación",
        "temperature": "Temperatura",
        "volumeUnit": "Unidad de Volumen",
        "distanceUnit": "Unidad de Distancia",
        "speedUnit": "Unidad de Velocidad",
        "google_api": "Clave de la API de Google"
    },
    "editAttribute": {
        "attributes_att": "Atributos",
        "attributes_Info": "Información",
        "attributes_save": "Atributo computado registrado con éxito.",
        "attributes_vincu": "Vinculando atributo al dispositivo",
        "attributes_Add": "Registrando su atributo...",
        "attributes_Del": "Atributo computado eliminado con éxito.",
        "attributes_Question1": "¿Realmente desea eliminar este atributo computado?",
        "attributes_Question2": "¿Está seguro?",
        "attributes_Devices": "Dispositivos",
        "attributes_resultado": "Resultado",
        "attributes_Please_input": "Por favor, ingrese",
        "attributes_não_Testar": "No Probar",
        "attributes_testar_Attribute": "Probar Atributo en",
        "attributes_expresion": "Expresión",
        "attributes_boolean": "Booleano",
        "attributes_number": "Número",
        "attributes_string": "Cadena",
        "attributes_typeOut": "Tipo de Salida",
        "attributes_Attribute": "Atributos",
        "attributes_description": "Descripción",
        "attributes_command": "Comando",
        "attributes_add_attribute": "Agregar Atributo",
        "attributes_Not": "Ningún tipo disponible",
        "attributes_grupo": "Grupo"
    },
    "group": {
        "add": "Registrar Grupo",
        "search": "Buscar Grupo",
        "new": "Crear Grupo",
        "title": "Gestionar Grupos",
        "groups": "Grupos",
        "group": "Grupo",
        "edit": "Editar",
        "name": "Nombre del Grupo",
        "father": "Grupo Padre",
        "attributes": "Atributos"
    },
    "server": {
        "tarkan": "Tarkan",
        "restart": "Reiniciar Servidor",
        "lazyDeletion": "Eliminación en Modo Seguro",
        "enableQrDriverId": "Habilitar Check-In por QrCode",
        "enableAdvancedPerms": "Sistema de Permisos Avanzados",
        "language": "Idioma",
        "enableLockUnlock": "Mostrar botones de bloqueo y desbloqueo",
        "deviceLimit": "Límite de Dispositivos del Servidor",
        "saving": "Guardando sus cambios",
        "saved": "Operación realizada con éxito.",
        "server": "Servidor",
        "preferences": "Preferencias",
        "permissions": "Permisos",
        "map": "Mapa",
        "latitude": "Latitud",
        "longitude": "Longitud",
        "zoom": "Zoom",
        "twelveHourFormat": "Reloj de 12 Horas",
        "registration": "Registro",
        "bingKey": "Clave Bing",
        "coordinateFormat": "Formato de Coordenada",
        "limitCommands": "Limitar Comandos",
        "deviceReadonly": "Dispositivos Solo Lectura",
        "google_key": "API Google",
        "readOnly": "Solo Lectura",
        "restartServer": "Al ejecutar esta acción, el sistema puede tardar unos minutos en volver a estar operativo",
        "restartServer1": "¿Desea realmente reiniciar el servidor?",
        "grade": "Grados",
        "minute": "Minutos",
        "seconds": "Segundos",
        "showStops": "Paradas de vehículos",
        "showEvents": "Eventos de vehículos",
        "enableBilling": "Financiero",
        "restartServerResult": "Servidor reiniciando..."
    },
    "driver": {
        "driver": "Conductor",
        "drivers": "Conductores",
        "name": "Nombre",
        "saving": "Guardando conductor",
        "saved": "¡Conductor guardado con éxito!",
        "saveError": "¡Ocurrió un error al guardar el conductor!",
        "uniqueId": "Identificador Único",
        "search": "Buscar Conductor",
        "selectError": "¡Debe seleccionar un conductor!",
        "confirmDelete": "Está eliminando el conductor \"%name%\", ¿desea realmente continuar?",
        "deleteSuccess": "Conductor eliminado con éxito.",
        "add": "Registrar Conductor",
        "remove": "Eliminar Conductor",
        "litersx100km": "Combustible Consumido x 100 KM",
        "fuelCost": "Costo de Combustible",
        "edit": "Editar Conductor",
        "newDriver": "Nuevo Conductor",
        "editDriver": "Editar Conductor",
        "qrDriver": {
            "enable": "QRCode - Habilitar Acceso",
            "username": "Nombre de Usuario",
            "password": "Contraseña",
            "behaviorRules": "Reglas de Comportamiento del Sistema",
            "unlockDevice": "Desbloquear Dispositivo",
            "autoLogout": "Auto Logout al Apagar la Ignición",
            "lockDevice": "Bloquear el Dispositivo sin Conductor",
            "lockDeviceTimeout": "Realizar el Bloqueo Después de",
            "autoLogoutTimeout": "Realizar el Logout Después de"
        }
    },
    "geofence": {
        "geofences": "Geocercas",
        "switches": "Bloqueos",
        "search": "Buscar Geocerca",
        "add": "Nueva Geocerca",
        "name": "Título",
        "new": "Crear Geocerca",
        "edit": "Editar Geocerca",
        "attributes": "Atributos",
        "type": "Tipo de Geocerca",
        "line": "Línea",
        "circle": "Círculo",
        "polygon": "Polígono",
        "editArea": "Editar Geocerca",
        "totalArea": "Área Total",
        "lockOnEnter": "Bloquear al Entrar",
        "speedLimit": "Bloquear por Exceso de Velocidad",
        "lockOnExit": "Bloquear al Salir",
        "deleteConfirm": "¿Realmente desea eliminar esta geocerca?",
        "errors": {
            "FILL_NAME": "El nombre del campo es obligatorio."
        }
    },
    "calendar": {
        "title": "Gestionar Calendarios",
        "newCalendar": "Nuevo Calendario",
        "editCalendar": "Editar Calendario",
        "calendars": "Calendarios",
        "calendar": "Calendario",
        "name": "Título",
        "data": "Agendas",
        "add": "Agregar Calendario",
        "remove": "Eliminar Calendario",
        "edit": "Editar Calendario",
        "startDate": "Fecha y Hora de Inicio",
        "endDate": "Fecha y Hora de Fin",
        "repeat": "Repetir",
        "frequency": "Frecuencia",
        "confirmAdd": "Agregar",
        "expires": "Caduca en",
        "occurrencies": "Ocurrencias",
        "expiration": "Fecha de Fin",
        "frequencyes": {
            "daily": "Diariamente",
            "weekly": "Semanalmente",
            "monthly": "Mensualmente",
            "yearly": "Anualmente"
        },
        "expirations": {
            "never": "Nunca",
            "count": "Después de",
            "until": "Hasta"
        }
    },
    "command": {
        "savedCommands": "Comandos Guardados",
        "search": "Buscar Comando"
    },
    "maintenance": {
        "search": "Buscar Mantenimiento",
        "title": "Gestión de Mantenimientos",
        "newMaintenance": "Agregar Mantenimiento",
        "editMaintenance": "Editar Mantenimiento",
        "maintence": "Mantenimiento",
        "maintenance": "Mantenimientos",
        "maintenances": "Mantenimientos",
        "name": "Nombre",
        "type": "Tipo",
        "start": "Inicio",
        "cicle": "Ciclo",
        "add": "Agregar Mantenimiento",
        "remove": "Eliminar",
        "edit": "Editar",
        "types": {
            "totalDistance": "Distancia Total",
            "hours": "Horas"
        }
    },
    "share": {
        "add": "Crear Compartición",
        "edit": "Editar Compartición",
        "remove": "Eliminar Compartición"
    },


  
};

export default messages;
